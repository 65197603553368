export default {
	home: {
		PreferentialActivities: 'ฝากประจำ',
		Deposit: 'ฝากเงิน',
		ProductIntroduction: 'รายละเอียดผลิตภัณฑ์',
		OnlineCustomerService: 'บริการออนไลน์',
		HotProducts: 'ตัวเลือกยอดนิยม',
		Thailand: 'Thailand',
		canada: 'canada',
		Australia: 'Australia',
		NewYork: 'NewYork',
		Singapore: 'Singapore',
		England: 'England',
		Japan: 'Japan',
		India: 'India'
	},
	tab2: {
		InvestmentRecord: 'บันทึกการเงิน',
		title1: "Ušün Vanguard",
		title2: "BlackRock AeokAs La: aonuầuusẽưna:Isuño?-Blockdit tũuuwacwasśu aÍnнรัuนัnอ่าuแล:นักเปียนทีมีผู้ชังาบ1ล้านคบลองใช้แพลตฟอร์มนีเพือได้ไอเดียใหม่ๆแล้วอาจพบว่าสังคมนีเหมาะกั บคนเช่นคุณ.",
		title3: "-Blockdit tũuuwacwasśu aÍnнรัuนัnอ่าu แล:นักเปียนทีมีผู้ชังาบ1ล้านคบลองใช้แพลตฟอร์มนีเพือได้ไอเดียใหม่ๆแล้วอาจพบว่าสังคมนีเหมาะกั บคนเช่นคุณ.",
		title4: "-BlackRock เป็นบริษัทจัดการการลงทุนของสHรัฐอเมริกาซึงถูกก่อดังขึบในปี1988น่าโดยกลุ่มนักลงทุน และนักธุรกิจชาวอเมริกันรวมกันทังหมด8คนโดยจุดﺎรีมด้นของการก่อด้งกองกุมนีขึบมาเรีมมาจากการทีกลุ่มผู้ก่อด้ ง ได้นึงคุยกับในНﺌองๆหนึงและเห็นพ้องต้องกันว่า พวกเขาน่าจะจัดตังบริษัทจัดการการลงทุมขึนมาเพือบริหารจัดการเจืบทุแฉยคำมีงถึงพลประโยชน์สูงสุดแก่ลูกค้ากายใต้ความเสียงทียอมรับเด้จากความด้งเจในวันนับทำให้เกิดBlackRock ปึบมาша: 11 ÜH áố ioภกท ี Black Rock ถูกก่อตังขิน.man0наักทรัพยบริษัทก็ได้เข้ามาระดมกุมในตลาดหลักทรัพย์นวยอร์ก",
		title5: "ก็ได้เข้ามาระดมกุมใน",
		title6: "บริษัทก็ได้เข้ามาระดมทุมในตลาดหลักทรัพย์นิวยอร์กเดยในป ี 1999.มูลค่าทรัพย์สืบภายใต้การบรีหารจัดการเท่ากั บ4.9 ล้านล้านบาทขณะทีปัจจุบันมุลค่าดังกล่าวเพีมขึนจนกลายเป็น 234 ล้านล้านบาท หรือเพีมขึนถึ ง 48เท่าวันนี BlackRockคือบริษัทจัดการการลงทุนที่ใหญ่สุดในโลกเมือวัดโดยมูลค่าทรัพย์สินภายใต้การบริหารจัดการ หรือที่เรียกกันว่าAssetUnder Management (AUM)",
		title7: "1. BlackRock ụaค่าทรัพย์สินกายใด้การบริหารจัดการมูลค่า 234ล้านล้าบบาท",
		title8: "2. Vanguard Group Цаплгšw éãนภายใต้ารมริHnsจัดการụลค่า 183 ล้าแล้าบบาท",
		title9: "3. UBS Group AG ụạaớinsรัพย์สินภายใต้การบริHnsจัดตารมูลค่า 105ล้านล้านบาทIGยสัดส่วunารลงทุนจากụลค่าทรัพย์สิบกายใต้การบริหารของBlack Rock Aือ",
		title10: "- Aqu 52%",
		title11: "-cnsnansHüB1%",
		title12: "- tōuao 7%",
		title13: "- ãunšwúóu 9 10%",
		title14: "ขณะทีเมือแป่งตามกลุ่มสูกค้าจากมูลค่าทรัพย์สินภายใต้การบริหารของ BlackRockùu",
		title15: "-70% ﺎป็unaịuạูกค้าในทวีปอเมริตา",
		title16: "- 25% ﺎữlunaịuạnค้าใunวีปยุโรU α:วัuออกกลาง และแอพริกา",
		title17: "-5% เป็นกลุ่มลูกค้าใunวีปเอเชย",
		title18: "สรุปแล้ว BlackRockจ:เน้นการลงทุแในหุ้นแล:ลูกค้ากลุ่มหลักของบริษักจะอยู่ในทวีปอพริกาขณะที่ในปรจจุบัน BlackRockให้บริการแก่นักลงทุมในกว่า 100 ประเทศที่ọIan แล:BlackRock ยังเป็นหนึงในบริษัทที่ถูกคำนวณอยู่ในดัชนี S&P500 T0ย78808iəว่ า “BLK”.",
		title19: "ถือหุ้นใหญ่ในบริษัท",
		title20: "กุกวับน ี BlackRock เป็นผู้ถือทุ่นใหญ่ในหลายบรินัทระดับโลกทีพวกเราคุ่นเคย เข่น",
		title21: "- ถือหุ้น Apple จำบวน 1,069 ล้านหุ้นมูลคำ4.1 ล้านล้านบาก",
		title22: "- ถือหุ้น Microsoft จำuวu516 ล้านหุ้น มูลค่า 3.4 ล้านล้าบบาท",
		title23: "- ถือหุน Amazon.com จำบวน27ล้านหุ้นบุลค่า2.6ล้านล้านบาท",
		title24: "- ถือหุนFacebook จำนวน158 ล้านหุ้น มูลค่า 1.3 ล้านลำบบาท",
		title25: "แล:ในกรณีของ Apple ด้วยความที BlackRock เป็น1ไน 2 ผู้ถือุนใหญ่ของAppleđึงทำให้หนึงในผู้ร่วมก่อตังของBlackRock ได้เป็นหนึงในคณะกรรมการﻦองUSษั n Apple อีกด้วยแล้วผลประกอบการของBlackRock เป็นอย่างIS?",
		title26: "₫ 2017 shยได ้375,000 ล้านบาก กำIs 149,000 ล้านบาท",
		title27: "0 2018 snยได้426,000 ằnuunn กำIs 129,000 ầnuunn",
		title28: "Ü 2019 shยId463,000 ầnuunn nhIs 135,000 ầnuunn",
		title29: "ขru:пีปจจุบิน มูลค่าบริษักของ BlackRock ạion:73.2 ล้านล้านUnn",
		title30: "ชิงเพืบชิบกว่า 21ล้านล้านบาก จาก ป ี 2010 กีเคยอยู่ที 1.1ล้ามล้าบบาท",
		title31: "ซึงกำไรของBlackRock ปลาสุดนืบเมากกว่ากำISUəงธนาคารพาณิชย์ขบาดอัญ"
	},
	tab4: {
		Loginnow: 'เข้าสู่ระบบทันที',
		RegisterNow: 'ลงทะเบียนเลย',
		Withdrawcash: 'ถอน',
		deposit: 'ฝาก',
		profitAndLoss: 'กำไรและขาดทุนวันนี้',
		ProductIntroduction: 'รายละเอียดผลิตภัณฑ์',
		FundDetails: 'รายละเอียดการเงิน',
		InvestmentRecord: 'บันทึกการเงิน',
		PreferentialActivities: 'ฝากประจำ',
		SecurityCenter: 'ศูนย์รักษาความปลอดภัย',
		Contactcustomerservice: 'ติดต่อฝ่ายบริการลูกค้า',
		Setup: 'การตั้งค่า',
		shezhiyhk: 'โปรดตั้งค่าบัตรธนาคารก่อน',
		shezhitxmm: 'กรุณาตั้งรหัสผ่านการถอนเงินก่อน'
	},
	login: {
		Loginnow: 'เข้าสู่ระบบทันที',
		QuickRegistration: 'ลงทะเบียนด่วน',
		Pleaseenteryourusernamephonenumber: 'กรุณากรอกชื่อผู้ใช้/หมายเลขโทรศัพท์มือถือ',
		Pleaseenterapassword: 'กรุณาใส่รหัสผ่าน'
	},
	registe: {
		RegisterNow: 'ลงทะเบียนเลย',
		Logintoanexistingaccount: 'เข้าสู่ระบบบัญชีที่มีอยู่',
	},
	shezhi: {
		languagesettings: 'ตั้งค่าภาษา',
		Switchaccount: 'ออกจากระบบ'
	},
	yuyanshezhi: {
		languagesettings: 'ตั้งค่าภาษา'
	},
	editbank: {
		Addabankcard: 'เพิ่มบัตรธนาคาร',
		RealName: 'ชื่อและนามสกุล',
		BankCardNumber: 'เลขบัญชีธนาคาร',
		BankAddress: 'ชื่อธนาคาร',
		Pleaseenteryourrealname: 'กรุณากรอก ซื่อ และนามสกุล',
		Pleaseenteryourbankcardnumber: 'เลขบัญชีธนาคาร',
		Pleaseenterthebankaddress: 'ชื่อธนาคาร'
	},
	remen: {
		AccountCurrency: 'ยอดเงินคงเหลือ',
		Recharge: 'ข้อมูล',
		BasicsROOM: 'ห้องระดับเริ่มต้น',
		GrandmasterROOM: 'ห้องระดับกลาง',
		expertROOM: 'ห้องระดับ VIP',
		siperVIP: 'ซุปเปอร์วีไอพี',
		Singleminimumpurchase: 'การซื้อขั้นต่ำครั้งเดียว',
		Entertheminimumamount: 'ใส่จำนวนเงินขั้นต่ำ',
		Numberoftransactions: 'จำนวนธุรกรรม',
		people: 'ผู้คน'
	},
	jiaoyizhongxin: {
		TransactionHistory: 'บันทึกการทำธุรกรรม',
		v2_until: 'จนถึง',
		v2_getting: 'กำลังรับ',
		User: 'ผู้ใช้',
		Numberofrounds: 'จำนวนรอบ',
		TradeCategory: 'หมวดหมู่ธุรกรรม',
		SingleRoundTrading: 'ซื้อขายรอบเดียว',
		Operation: 'ดำเนินงาน',
		v2_changui: 'ตลาด',
		v2_teshu: 'พิเศษ',
		v2_shuwei: 'ดิจิทัล',
		v2_duo: 'ทำมากขึ้น',
		v2_kong: "สั้น",
		v2_dan: 'คำสั่ง',
		v2_shuang: 'ทำสองเท่า',
		v2_jiyang: "เพิ่มขื้น",
		v2_jiyin: "สั้นลง",
		v2_teshu_4: 'การเลือกตั้งทั่วไปของเสือดาว',
		v2_teshu_5: 'การเลือกแบบตรง',
		v2_teshu_6: 'การเลือกคู่',
		v2_1: 'สูงร้อยสิบ',
		v2_2: 'ต่ำร้อยสิบสูงร้อย',
		v2_3: 'สูงร้อย',
		v2_4: 'ระดับต่ำสุดหลายร้อย',
		v2_5: 'สูงสิบ',
		v2_6: 'สิบเสียงต่ำ',
		Buy: 'ซื้อ',
		gendanbt: 'สารคดี',
		di: 'ครั้งแรก',
		lun: 'วงล้อ',
		alertqdgd: 'คุณแน่ใจเกี่ยวกับการสั่งซื้อ?',
		queding: "ยืนยัน",
		quxiao: "ยกเลิก",
		alertqdgd: 'คุณแน่ใจเกี่ยวกับการสั่งซื้อ?',
		queding: "ยืนยัน",
		quxiao: "ยกเลิก",
		v2_duodan: "คำสั่งเพิ่มการ",
		v2_kongdan: "คำสั่งช้ำ",
		v2_duoshuang: "พิเศษ",
		v2_kongshuang: "ทำสามเท่า"
	},
	tabbar: {
		Home: 'หน้าหลัก',
		Recharge: 'ข้อมูล',
		Trend: 'ประวัต',
		My: 'ของฉัน'
	},
	srdlmm: 'ป้อนรหัสผ่านเข้าสู่ระบบ',
	tixianmima: "รหัสผ่านการถอน",
	denglumima: "รหัสผ่านในการเข้าสู่ระบบ",
	zhong: "กลาง",
	anquandengji: "ระดับความปลอดภัย",
	anquanzhongxin: "ศูนย์รักษาความปลอดภัย",
	zhanghaowuyanzhengfangshi: "ไม่มีวิธีการยืนยันสำหรับบัญชี โปรดลืมรหัสผ่านเก่า",
	lianxikefu: "ติดต่อฝ่ายบริการลูกค้า",
	xindenglumimaguize: "กรุณาใส่รหัส 6-16 หลักและตัวอักษรผสมกันสำหรับรหัสผ่านเข้าสู่ระบบใหม่",
	xinmima: "รหัสผ่านใหม่",
	querenxinmima: "ยืนยันรหัสผ่านใหม่",
	jiumima: "รหัสผ่านเก่า",
	xiugaidenglumima: "แก้ไขรหัสผ่านเข้าสู่ระบบ",
	shurujiumima: "กรุณาใส่รหัสผ่านเก่า",
	xiugaitixianmima: "เปลี่ยนรหัสผ่านการถอนเงิน",
	qsrtxmm: 'กรุณาใส่รหัสผ่านการถอนเงิน',
	yuanmimabuyongshuru: "รหัสผ่านเดิมไม่ต้องใส่ในการตั้งค่าครั้งแรก",
	qingshuruxinmima: "กรุณาใส่รหัสผ่านใหม่ของคุณ",
	queding: "ยืนยัน",
	tianjiayinhangka: "เพิ่มบัตรธนาคาร",
	profit: {
		biaoti: 'กิจกรรมพิเศษ',
		name: '7วัน Financial Management',
		jine: 'จำนวน',
		shouru: 'รายได้',
		cunkuanshijian: 'เวลาฝาก',
		daoqi: 'วันที่หมดอายุ',
		cunkuanjine: 'จำนวนเงินฝาก',
		cunkuan: 'ฝากเลย',
		shurujine: 'ใส่จำนวนเงิน',
		goumai: 'ซื้อ',
		quxiao: 'ยกเลิก',
	},
	fund: {
		biaoti: 'รายละเอียดกองทุน',
		tikuan: 'เบิกเงิน',
		zhuangtai: 'สถานะ',
		shijian: 'เวลา',
		nomore: 'ไม่มีข้อมูลเพิ่มเติม',
	},
	txmoney: {
		Withdrawcash: 'ถอน',
		AmountofCashWithdrawal: 'จำนวนเงินที่สามารถถอนได้',
		Withdrawcashamount: 'จำนวนเงินที่ถอน',
		WithdrawPassword: 'รหัสผ่านการถอน',
		Pleaseenterthewithdrawalamount: 'กรุณากรอกจำนวนเงินที่ถอน',
		Pleaseenteryourwithdrawalcode: 'กรุณาใส่รหัสผ่านการถอนเงิน'
	},
	touzijilu: {
		biaoti: 'บันทึกการลงทุน',
		lunshu: 'จำนวนรอบ',
		danbijine: 'จำนวนเงินลงทุนเพียงครั้งเดียว',
		huoli: 'จำนวนกำไร',
		leixing: 'ประเภทธุรกรรม',
		zhuangyai: 'สถานะ',
		shijian: 'เวลา',
		Pendingearnings: 'รายได้ที่รอดำเนินการ',
		Investmentsuccess: 'ความสำเร็จในการลงทุน',
		investmentfailure: 'ความล้มเหลวในการลงทุน'
	},
	game: {
		game1: 'ปกป้องมหาสมุทร',
		game2: 'ระยะห่าง',
		game3: 'รอบ',
		game4: 'สิ้นสุด',
		game5: 'เล็ก',
		game6: 'คู่',
		game7: 'ผลรวม',
		game8: 'เสมอ:',
		game9: 'บันทึกการจับสลาก',
		game10: 'แผนภูมิการเคลื่อนไหว',
		game11: 'แนวโน้มขนาด',
		game12: 'แนวโน้มคู่คี่',
		game13: 'ใหญ่',
		game14: 'เดี่ยว',
		game15: 'จำนวนการเดิมพันในรอบนี้',
		game16: 'เดิมพัน',
		game17: 'ดูการเดิมพันในรอบนี้',
		game18: 'ดูข้อมูลการติดตาม',
		game19: 'กำลังเดิมพันในรอบ',
		game20: 'ติดตาม',
		game21: 'ตั้งค่าหยุดติดตามโดยอัตโนมัติหลังจากถูกรางวัลในจำนวนรอบที่กำหนด',
		game22: 'หากไม่ได้ถูกรางวัล จะหยุดติดตามหลังจากเล่นถึงจำนวนรอบที่ตั้งค่า',
		game23: 'เลือกจำนวนรอบ:',
		game24: 'ซื้อล่วงหน้า',
		game25: 'ยอดรวมการซื้อล่วงหน้า:',
		game26: 'ผสม',
		game27: 'หมายเลขพิเศษ',
		game28: 'ซื้อล่วงหน้า',
		game29: 'วิธีเล่น:',
		game30: 'ใหญ่เดี่ยว:',
		game31: 'ใหญ่คู่:',
		game32: 'เล็กเดี่ยว:',
		game33: 'เล็กคู่:',
		game34: 'เลขโต:',
		game35: 'เลขเล็ก:',
		game36: 'เลขเต็ง:',
		game37: 'สีแดง:',
		game38: 'สีเขียว:',
		game39: 'สีน้ำเงิน:',
		game40: 'เลือกวิธีเล่น คลิกเพื่อซื้อด้วยจำนวนเงิน:',
		game41: 'จำนวนเงินที่ซื้อ:',
		game42: 'ติดตาม:',
		game43: 'ซื้อ:',
		game44: 'รีเซ็ต:',
		game45: 'ย้อนกลับ:',
		game46: 'ซื้อเข้า:',

	}
}