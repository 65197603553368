<template>
</template>

<script>
	// 功能引入
	import {
	    toRefs,
	    reactive,
	    onMounted,
		onUnmounted
	} from 'vue'
	import { useRouter } from "vue-router";
	import { loginstatus } from "@/api/api";


	export default {
	    setup() {
			
			let router = useRouter();
			
	        // 数据
	        const State = reactive({
				
	        });

	        // 方法
	        const Function = reactive({
				onload(){
					loginstatus().then((res) => {
						if(res.data.info==0){
							sessionStorage.removeItem('username')
							router.push('login')
						}
					}).catch((error) => {
					  console.log(error)
					});
				}
	        });
	        // 接口
	        const Interface = reactive({});

	        onMounted(() => {
				Function.onload()
	        });
			onUnmounted(() => {
	            //销毁
	        });
	        return {
	            ...toRefs(State),
	            ...toRefs(Function),
	            ...toRefs(Interface),
	        }
	    },
	}
</script>

<style lang='scss'>
</style>