<template>
  <div class="sign_pop">
    <div class="con">
      <div class="bar">
        每日签到 <img class="close" src="./static/image/icon_close.png" />
      </div>
      <div class="ele">
        <img class="money" src="./static/image/boosting/icon_money.png" />
        <img class="sign" src="./static/image/boosting/icon_sign.png" />
        <b>1</b>
        <div class="bottom">星期一</div>
      </div>
      <div class="ele">
        <img class="money" src="./static/image/boosting/icon_money.png" />
        <img class="sign" src="./static/image/boosting/icon_sign.png" />
        <b>5</b>
        <div class="bottom">星期二</div>
      </div>
      <div class="ele">
        <img class="money" src="./static/image/boosting/icon_money.png" />
        <img class="sign" src="./static/image/boosting/icon_sign.png" />
        <b>10</b>
        <div class="bottom">星期叁</div>
      </div>
      <div class="ele">
        <img class="money" src="./static/image/boosting/icon_money.png" />
        <img class="sign" src="./static/image/boosting/icon_sign.png" />
        <b>20</b>
        <div class="bottom">星期四</div>
      </div>
      <div class="ele">
        <img class="money" src="./static/image/boosting/icon_money.png" />
        <img class="sign" src="./static/image/boosting/icon_sign.png" />
        <b>30</b>
        <div class="bottom">星期五</div>
      </div>
      <div class="ele">
        <img class="money" src="./static/image/boosting/icon_money.png" />
        <img class="sign" src="./static/image/boosting/icon_sign.png" />
        <b>40</b>
        <div class="bottom">星期六</div>
      </div>
      <div class="ele2">
        <img class="money" src="./static/image/boosting/icon_money.png" />
        <img class="sign" src="./static/image/boosting/icon_sign.png" />
        <b>50</b>
        <div class="bottom">星期天</div>
      </div>
      <div class="sign_btn">签到</div>
    </div>
  </div>

  <div
    id="signSuc"
    style="
      position: fixed;
      color: white;
      font-size: 12px;
      text-align: center;
      padding: 3px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      background: rgba(0, 0, 0, 0.7);
      width: 100px;
      height: 30px;
      line-height: 26px;
      border-radius: 6px;
      z-index: 999;
      display: none;
    "
  >
    签到成功
  </div>

  <div
    id="signed"
    style="
      position: fixed;
      color: white;
      font-size: 12px;
      text-align: center;
      padding: 3px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      background: rgba(0, 0, 0, 0.7);
      width: 100px;
      height: 30px;
      line-height: 26px;
      border-radius: 6px;
      z-index: 999;
      display: none;
    "
  >
    今日已经签到
  </div>
  <div class="singing-main" style="position: sticky; height: auto; top: auto">
    <div class="header">
      <div class="header-left">
        <img src="../../static/img/singing/singing-logo.png" />
        <span>Singapore Lottery</span>
      </div>
      <div class="header-right">
        <div
          class="dream-header"
          style="display: block; position: static; background: #1c377c; width: 70%"
        >
          <!--语言选择-->
          <div
            class="lang_cur_s"
            style="display: block"
            v-if="recordTheCurrent == 'zh'"
            @click="openLanguage()"
          >
            <img id="vector" src="../../static/img/singing/Vector.png" />
            简体

            <img
              class="login-logo"
              id="login-logo"
              src="./static/image/arrow_bottom.png"
            />
          </div>
          <div
            class="lang_cur_t"
            style="display: block"
            v-if="recordTheCurrent == 'tc'"
            @click="openLanguage()"
          >
            <img id="vector" src="../../static/img/singing/Vector.png" />
            繁体
            <img
              class="login-logo"
              id="login-logo"
              src="./static/image/arrow_bottom.png"
            />
          </div>
          <div
            class="lang_cur_e"
            style="display: block"
            v-if="recordTheCurrent == 'en'"
            @click="openLanguage()"
          >
            <img id="vector" src="../../static/img/singing/Vector.png" />
            English
            <img
              class="login-logo"
              id="login-logo"
              src="./static/image/arrow_bottom.png"
            />
          </div>
          <div
            class="lang_cur_j"
            style="display: block"
            v-if="recordTheCurrent == 'jp'"
            @click="openLanguage()"
          >
            <img id="vector" src="../../static/img/singing/Vector.png" />
            Jpan
            <img
              class="login-logo"
              id="login-logo"
              src="./static/image/arrow_bottom.png"
            />
          </div>
          <div class="lang_lump" v-if="languageDisplay">
            <a
              id="lang_s"
              class="lang"
              style="color: rgb(0, 0, 0)"
              @click="changelang('zh')"
            >
              <img class="login-logo" src="./static/image/869/icon_s.png" />
              简体
              <img
                id="selected_s"
                style="display: block"
                class="login-logo"
                src="./static/image/869/icon_lang_selected.png"
                v-if="recordTheCurrent == 'zh'"
              />
              <img
                id="unselected_s"
                class="login-logo"
                src="./static/image/869/icon_lang_unselect.png"
                style="display: block"
              />
            </a>
            <a id="lang_t" class="lang" @click="changelang('tc')">
              <img class="login-logo" src="./static/image/869/icon_t.png" />
              繁体
              <img
                id="selected_t"
                style="display: block"
                class="login-logo"
                src="./static/image/869/icon_lang_selected.png"
                v-if="recordTheCurrent == 'tc'"
              />
              <img
                id="unselected_t"
                class="login-logo"
                src="./static/image/869/icon_lang_unselect.png"
              />
            </a>
            <a id="lang_e" class="lang" @click="changelang('en')">
              <img class="login-logo" src="./static/image/869/icon_e.png" />
              English
              <img
                id="selected_e"
                style="display: block"
                class="login-logo"
                src="./static/image/869/icon_lang_selected.png"
                v-if="recordTheCurrent == 'en'"
              />
              <img
                id="unselected_e"
                class="login-logo"
                src="./static/image/869/icon_lang_unselect.png"
              />
            </a>
            <a id="lang_j" class="lang" @click="changelang('jp')">
              <img class="login-logo" src="./static/image/869/icon_j.png" />
              Jpan
              <img
                id="selected_j"
                style="display: block"
                class="login-logo"
                src="./static/image/869/icon_lang_selected.png"
                v-if="recordTheCurrent == 'jp'"
              />
              <img
                id="unselected_j"
                class="login-logo"
                src="./static/image/869/icon_lang_unselect.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="singing-content">
      <div class="norow-swipe">
        <van-swipe
          class="my-swipe"
          :autoplay="3000"
          :show-indicators="false"
          indicator-color="white"
        >
          <van-swipe-item>
            <img src="../../static/img/singing/swi1.jpg" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../static/img/singing/swi2.jpg" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../static/img/singing/swi3.jpg" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../static/img/singing/swi4.jpg" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../static/img/singing/swi5.jpg" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../static/img/singing/swi6.jpg" />
          </van-swipe-item>
        </van-swipe>
        <div class="swipe-info">
          <img src="../../static/img/singing/child_s.png" />
        </div>
      </div>
      <div class="row-swipe">
        <div class="title">{{ $t("singing.title1") }}</div>
        <div class="swipe">
          <div class="scroll-container">
            <ul class="scroll-list" ref="list">
              <li v-for="(item, index) in dataList" :key="index">
                <span>{{ item.name }}</span>
                <span style="margin-left: 5%"
                  >{{ $t("singing.title2") }}{{ item.spice
                  }}{{ $t("singing.title3") }}</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px">
        <img src="../../static/img/singing/photo2_s.jpg" />
      </div>
      <div>
        <img src="../../static/img/singing/photo3_s.jpg" />
      </div>
      <div class="singing-amount">
        <div class="amount">
          <div class="left">
            <span>{{ $t("singing.title4") }}</span>
            <span>3.95280</span>
          </div>
          <div class="right">
            <span>{{ $t("singing.title5") }}</span>
            <span>15.43036</span>
          </div>
        </div>
      </div>
      <div class="singing-title">
        <div class="title">
          <span>「{{ $t("singing.title6") }}」</span>
        </div>
        <div class="singing-title-content">
          <span>
            {{ $t("singing.title7") }}
          </span>
        </div>
      </div>
      <div class="singing-title">
        <div class="title">
          <span>{{ $t("singing.title8") }}</span>
        </div>
        <div class="singing-title-content">
          <span>
            {{ $t("singing.title9") }}
          </span>
        </div>
      </div>
      <div class="singing-title">
        <div class="title">
          <span>{{ $t("singing.title10") }}</span>
        </div>
        <div class="singing-title-content">
          <span>
            {{ $t("singing.title11") }}
          </span>
        </div>
      </div>
      <div class="singing-title">
        <div class="title">
          <span>{{ $t("singing.title12") }}:</span>
        </div>
        <div class="singing-title-content">
          <p>{{ $t("singing.title13") }}</p>
          <p>
            {{ $t("singing.title14") }}
          </p>
          <p>
            {{ $t("singing.title15") }}
          </p>
          <p>
            {{ $t("singing.title16") }}
          </p>
        </div>
      </div>
      <div class="singing-title">
        <div class="title">
          <span>{{ $t("singing.title17") }}:</span>
        </div>
        <div class="singing-title-content">
          <p>
            {{ $t("singing.title18") }}
          </p>
          <p>
            {{ $t("singing.title19") }}
          </p>
          <p>
            {{ $t("singing.title20") }}
          </p>
          <p>
            {{ $t("singing.title21") }}
          </p>
        </div>
      </div>
      <div class="singing-title" style="border-top: 1px solid #ffffff">
        <div class="title">
          <span>{{ $t("singing.title22") }}</span>
          <span>{{ $t("singing.title23") }}</span>
          <span>{{ $t("singing.title24") }}</span>
        </div>
      </div>
      <div class="singing-list">
        <div class="item">
          <img src="../../static/img/singing/lump_ele_01.png" />
          <span>{{ $t("singing.title25") }}</span>
          <button @click="juanzeng">{{ $t("singing.title28") }}</button>
        </div>
        <div class="item">
          <img src="../../static/img/singing/lump_ele_02.png" />
          <span>{{ $t("singing.title26") }}</span>
          <button @click="juanzeng">{{ $t("singing.title28") }}</button>
        </div>
        <div class="item">
          <img src="../../static/img/singing/lump_ele_03.png" />
          <span>{{ $t("singing.title27") }}</span>
          <button @click="juanzeng">{{ $t("singing.title28") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { qiandao, juanzeng, showqiandao } from "@/api/api";
import { showToast } from "vant";

export default {
  data() {
    return {
      lang: "",
      dataList: [
        {
          name: "***am2",
          spice: "2322",
        },
        {
          name: "***g0",
          spice: "3222",
        },
        {
          name: "***1p3",
          spice: "12322",
        },
        {
          name: "***23l",
          spice: "4509",
        },
        {
          name: "***89p",
          spice: "9870",
        },
        {
          name: "***23k",
          spice: "8873",
        },
      ],
      scrollInterval: null,
      recordTheCurrent: "tc",
      languageDisplay: false,
    };
  },
  mounted() {
    this.lang = sessionStorage.getItem("locale") || "tc";
    this.startScroll();

    $(".badge_heart").click(function () {
      $(".sign_pop").show();
    });
    $(".sign_pop .con .bar .close").click(function () {
      $(".sign_pop").hide();
    });
    const self = this;
    $(".sign_pop .sign_btn").click(function () {
      const day = new Date().getDay();
      self.setSign();
      // setSign($(this).find('b').text())
    });
  },
  methods: {
    //捐赠接口
    juanzeng() {
      juanzeng().then((res) => {
        showToast(res.info);
      });
    },
    // 签到接口
    setSign() {
      qiandao().then((result) => {
        console.log(result);
        if (result.data) {
          $("#signSuc").show();
        } else {
          $("#signed").show();
        }
        setTimeout(() => {
          $("#signSuc").hide();
          $("#signed").hide();
          window.location.reload();
        }, 1000);
        this.getSignShow();
      });
    },
    // 签到天数展示
    getSignShow() {
      showqiandao().then((result) => {
        const data = result;
        if (Array.isArray(data.data.url)) {
          for (let i = 0; i < data.data.url.length; i++) {
            const dayOfWeek = data.data.url[i].w;
            const sign = data.data.url[i].sign;

            // 星期几的索引，从1开始
            const dayIndex = dayOfWeek;

            // 根据签到状态设置显示和隐藏
            if (sign === 1) {
              $(`.sign_pop .ele:eq(${dayIndex - 1}) .money`).hide();
              $(`.sign_pop .ele:eq(${dayIndex - 1}) .sign`).show();
            } else {
              $(`.sign_pop .ele:eq(${dayIndex - 1}) .money`).show();
              $(`.sign_pop .ele:eq(${dayIndex - 1}) .sign`).hide();
            }
          }
        } else {
          console.error("Data is not an array.");
        }
      });
    },
    changelang(type) {
      this.recordTheCurrent = type;
      this.languageDisplay = false;
      this.$i18n.locale = type;
      this.lang = type;
      sessionStorage.setItem("locale", type);
    },
    openLanguage() {
      this.languageDisplay = this.languageDisplay ? false : true;
    },
    startScroll() {
      this.scrollInterval = setInterval(() => {
        const list = this.$refs.list;
        if (list) {
          const firstItem = list.querySelector("li:first-child");
          list.appendChild(firstItem.cloneNode(true));
          list.removeChild(firstItem);
        }
      }, 2000);
    },
    stopScroll() {
      clearInterval(this.scrollInterval);
    },
  },
  beforeDestroy() {
    this.stopScroll();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/qweqwef.scss";
@import "static/css/app.cc8ec.css";
@import "static/css/869_style.css";
.singing-main {
  width: 100%;
  //height: 100vh;
  background: #1c377c;
  .header {
    //width: 100%;
    height: 45px;
    display: flex;
    justify-content: start;
    padding-top: 10px;
    padding-left: 2%;
    .header-left {
      width: 70%;
      display: flex;
      justify-content: start;
      img {
        width: 25px;
        height: 32px;
      }
      span {
        color: #ffffff;
        font-size: 15px;
        margin-top: 9px;
        margin-left: 2%;
      }
    }
    .header-right {
      // width: 30%;
    }
  }
  .singing-content {
    .norow-swipe {
      position: relative;
      .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: #39a9ed;
      }
      .swipe-info {
        padding-left: 10%;
        padding-right: 10%;
        position: absolute;
        top: 238px;
      }
    }
    .row-swipe {
      text-align: center;
      height: 140px;
      color: #ffffff;
      font-size: 14px;
      padding-top: 20px;
      padding-left: 3%;
      padding-right: 3%;
      .title {
        color: #ffffff;
        font-size: 14px;
      }
      .swipe {
        margin-top: 10px;
        .scroll-container {
          height: 100px;
          overflow: hidden;
          text-align: left;
          padding-left: 18%;
        }

        .scroll-list {
          margin: 0;
          padding: 0;
          list-style: none;
          animation: scroll 10s linear infinite;
          li {
            margin-top: 10px;
          }
        }

        @keyframes scroll {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(-100%);
          }
        }
      }
    }
    .singing-amount {
      margin-top: 20px;
      padding-left: 5%;
      padding-right: 5%;
      .amount {
        height: 85px;
        border-radius: 20px;
        background: #ffffff;
        display: flex;
        justify-content: start;
        font-size: 14px;
        color: #000000;
        padding-top: 18px;
        .left {
          width: 50%;
          height: 55px;
          border-right: 0.01rem solid #e6e6e6;
          text-align: center;
          //line-height: 50px;
          span {
            display: block;
            margin-top: 10px;
          }
        }
        .right {
          width: 50%;
          height: 55px;
          text-align: center;
          span {
            display: block;
            margin-top: 10px;
          }
        }
      }
    }
    .singing-title {
      margin-top: 20px;
      .title {
        //height: 41px;
        border-bottom: 1px solid #ffffff;
        span {
          display: block;
          padding: 0.2rem;
          color: white;
          font-weight: 600;
          font-size: 16px;
          line-height: 0.72rem;
        }
      }
      .singing-title-content {
        span {
          display: block;
          padding: 0.2rem;
          color: white;
          font-weight: normal;
          font-size: 0.24rem;
          line-height: 0.42rem;
        }
        p {
          padding: 0.2rem;
          color: white;
          font-weight: normal;
          font-size: 0.24rem;
          line-height: 0.42rem;
        }
      }
    }
    .singing-list {
      padding-top: 20px;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 50px;
      .item {
        background: #ffffff;
        border-radius: 10px;
        display: flex;
        justify-content: start;
        margin-bottom: 10px;
        img {
          width: 80px;
          height: 80px;
        }
        span {
          display: block;
          width: 40%;
          font-size: 16px;
          color: #000000;
          font-weight: 600;
          line-height: 55px;
          margin-left: 5%;
        }
        button {
          text-align: center;
          font-weight: bold;
          font-size: 0.32rem;
          color: white;
          width: 1.8rem;
          height: 0.8rem;
          line-height: 0.8rem !important;
          padding-top: 0;
          margin: 0 auto;
          margin-top: 0.4rem;
          background: linear-gradient(180deg, #274694 0%, #1c377c 100%);
          border: 0.01rem solid #ffffff;
          box-shadow: 0 0.04rem 0.08rem rgba(0, 0, 0, 0.15);
          border-radius: 0.4rem;
        }
      }
    }
  }
}
</style>
