<template>
    <div class="top-nav" :style="{backGround:backgroundColor}">
        <div v-if="showLeft" @click="jumurl"  class="it-row t-left">
            <img style="width: 28px" src="@/assets/images/tab1/arrow_back.png"/>
        </div>
        <div class="it-row t-c" :class="titleBold?'f-18-w':''">{{title}}</div>
        <div class="it-row t-right" v-if="showRight">
            <img style="width: 25px" src="@/assets/images/tab1/icon_bottom_home.png"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TopNav",
        // props:['title','showLeft','showRight']
        props:{
            title:{
                type:String,
                default:'',
            },
            showLeft:{
                type:Boolean,
                default:true,
            },
            showRight:{
                type:Boolean,
                default:false,
            },
            backgroundColor:{
                type:String,
                default:'#00499A',
            },
            titleBold:{//标题是否加粗
                type:Boolean,
                default:false,
            },
			gotourl:{
				type:String,
				default:'',
			}
        },
        mounted() {
            console.log('公共的头部组件--->>',6666)
        },
        methods:{
			jumurl(){
				const taht = this
				//console.log(this.gotourl)
				if(this.gotourl){
					this.$router.push({
						path: "/"+taht.gotourl
					});
				} else {
					this.$router.go(-1)
				}
			}
        }
    }
</script>

<style scoped lang="scss">
    .top-nav {
        height: 48px;
        background: #00499A;
        font-size: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 15px;
        text-align: center;
        color: white;
        justify-content: center;
        position: fixed;
        top: 0;
		z-index: 9999;
        .it-row {
            width: calc(100% / 3);
            white-space: nowrap;
        }

        .t-left {
            text-align: left;
        }

        .t-right {
            text-align: right;
        }
        .f-18-w{
            font-weight: bold;
            font-size: 18px;
        }

    }
</style>
