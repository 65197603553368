import { createI18n } from 'vue-i18n'
import en from './en'
import tc from './tc'
import thai from './thai'
import vnf from './vnf'
import zh from './zh'
import jp from './jp'

let locale = sessionStorage.getItem('locale') || '';

if (locale === '') {
    locale = 'en';
    sessionStorage.setItem('locale', locale);
}

export const setupI18n = (app) => {
    const i18n = createI18n({
		globalInjection: true,
		legacy: false,
        locale: locale,
        messages: {
            en,
			thai,
			vnf,
			tc,
            zh,
            jp,
        }
    })
    app.use(i18n)
}
