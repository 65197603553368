export default {
	home:{
		PreferentialActivities:'投资理财',
		Deposit:'充值存款',
		ProductIntroduction:'產品介紹',
		OnlineCustomerService:'線上客服',
		HotProducts:'熱門產品',
		Thailand:'Thailand',
		canada:'canada',
		Australia:'Australia',
		NewYork:'NewYork',
		Singapore:'Singapore',
		England:'England',
		Japan:'Japan',
		India:'India',
		ckgd:'查看更多',
		ljcj:'立即抽獎',
		czcj:'儲值抽獎',
		jcyx:'精彩遊戲',
		jzlb:'捐贈列表',
		ax_tips:'你們的長期支持對於推動益童計畫的成功發展和影響力的提升都起到了至關重要的作用。 這種持續的支持對社會福祉和兒童福利有著正面的影響，為他們提供了更好的生活和教育機會。',
		"768194-0": "體驗金",
		"768194-1": "简体",
		"768194-2": "繁体",
		"768194-3": "弘揚慈善傳統美德、促進社會文明進步、多多助力購聯手打造、在線數字娛樂平台、每日任務最高領取257760助力金、祝您生活愉快～",
		"768194-4": "新加坡大彩",
		"768194-5": "4D彩票，玩轉大獎",
		"768194-6": "進入",
		"768194-7": "萬字票",
		"768194-8": "尋寶大行動",
		"768194-9": "多多彩票",
		"768194-10": "幸運大獎，等你拿",
		"768194-11": "感謝每一位持續捐贈超過18個月的益童堅持者!",
		"768194-12": "捐赠",
		"768194-13": "錢包",
		"768194-14": "愛心奉獻",
        "985138-0": "登錄",
		"985138-1": "简",
		"985138-2": "繁",
		"985138-3": "愛心接力 讓心充滿希望",
		"985138-4": "歡迎光臨",
		"985138-5": "注冊",
		"985138-6": "輸入賬號",
		"985138-7": "輸入密碼",
		"985138-8": "立即登錄",
        "063348-0": "註冊",
		"063348-1": "简",
		"063348-2": "繁",
		"063348-3": "愛心接力 讓心充滿希望",
		"063348-4": "歡迎光臨",
		"063348-5": "登錄",
		"063348-6": "注冊",
		"063348-7": "請輸入6~20位字母、數字或組合",
		"063348-8": "請輸入6~16位的字母、數字組合",
		"063348-9": "請輸入邀請碼",
		"063348-10": "请填写您的代理人邀请码",
		"063348-11": "我已年滿18周歲且同意接受",
		"063348-12": "註冊條款",
		"063348-13": "馬上註冊",
		"063348-80":"请输入确认密码"
	},
	tab2:{
		InvestmentRecord: '財務記錄',
		title1: "烏申先鋒",
		title2: "黑石集團艾奧卡斯拉: 一個億萬富翁的投資平臺-Blockdit 團隊使用此平臺來獲得新的創意，也許會發現這個社會適合像您這樣的人。",
		title3: "-Blockdit 團隊使用此平臺來獲得新的創意，也許會發現這個社會適合像您這樣的人。",
		title4: "-黑石集團是一家總部位於美國的投資管理公司，成立於1988年，由一群投資者和商人創立。它已發展成為全球最大的投資管理公司之一，管理的資產達2340億美元。黑石集團為來自100多個國家的客戶提供投資服務，並在標普500指數上市，代號為“BLK”。",
		title5: "它在1999年在紐約證券交易所上市。",
		title6: "其創立時的總管理資產為49億美元，目前已增至2340億美元，增長了近48倍。黑石集團是全球最大的投資管理公司，以管理的資產（AUM）衡量。",
		title7: "1. 黑石集團的管理資產價值為2340億美元",
		title8: "2. 先鋒集團的管理資產價值為1830億美元",
		title9: "3. 瑞士銀行集團的管理資產價值為1050億美元。它是一家從客戶處接收投資的投資管理公司，由黑石集團管理。",
		title10: "- Aqu 52%",
		title11: "-cnsnansHüB1%",
		title12: "- tōuao 7%",
		title13: "- ãunšwúóu 9 10%",
		title14: "在追踪黑石集團管理的客戶群中",
		title15: "-70%的客戶位於北美",
		title16: "-25%的客戶位於歐洲和非洲",
		title17: "-5%的客戶位於亞洲",
		title18: "總結，黑石集團專注於股票投資，其主要客戶位於北美，同時目前為全球100多個國家的投資者提供服務。黑石集團還是包含在標普500指數中的公司之一，代號為“BLK”。",
		title19: "在公司中持有重要股份",
		title20: "黑石集團在一些全球公司中持有重要股份，例如",
		title21: "-持有蘋果公司10.69億股，價值4.1萬億美元",
		title22: "-持有微軟公司5.16億股，價值3.4萬億美元",
		title23: "-持有亞馬遜公司2,700萬股，價值2.6萬億美元",
		title24: "-持有臉書公司1.58億股，價值1.3萬億美元",
		title25: "就蘋果公司而言，黑石集團是前2大股東之一，使得黑石集團的其中一位共同創始人成為蘋果公司董事會成員。此外，黑石集團的業績如下：",
		title26: "2017年，其收入為3750億美元，淨收入為1490億美元",
		title27: "2018年，其收入為4260億美元，淨收入為1290億美元",
		title28: "2019年，其收入為4630億美元，淨收入為1350億美元",
		title29: "截至年結束時，黑石集團資產的市值為73.2萬億美元",
		title30: "它管理著超過21萬億美元，從2010年的1.1萬億美元增至今",
		title31: "黑石集團的利潤超過商業銀行的利潤。"
	},
	tab4:{
		Loginnow:'立即登录',
		RegisterNow:'立即注册',
		Withdrawcash:'提現',
		deposit: '存款',
		profitAndLoss: "今天的損益",
		ProductIntroduction:'產品介紹',
		FundDetails:'資金明細',
		InvestmentRecord:'投資記錄',
		PreferentialActivities:'投资理财',
		SecurityCenter:'安全中心',
		Contactcustomerservice:'聯系客服',
		Setup:'設定',
		shezhiyhk:'請先設置銀行卡',
		shezhitxmm:'請先設置提現密碼'
	},
	login:{
		Loginnow:'立即登录',
		QuickRegistration:'快速注册',
		Pleaseenteryourusernamephonenumber:'請輸入用戶名/手機號',
		Pleaseenterapassword:'請輸入密碼'
	},
	registe:{
		RegisterNow :'立即注册',
		Logintoanexistingaccount:'登入現有帳戶',
	},
	shezhi:{
		languagesettings:'語言設定',
		Switchaccount:'切換帳號'
	},
	yuyanshezhi:{
		languagesettings :'語言設定'
	},
	editbank:{
		Addabankcard:'添加銀行卡',
		RealName:'真實姓名',
		BankCardNumber:'銀行卡號',
		BankAddress:'銀行名称',
		Pleaseenteryourrealname:'請輸入真實姓名',
		Pleaseenteryourbankcardnumber:'請輸入銀行卡號',
		Pleaseenterthebankaddress:'請輸入銀行名称'
	},
	remen:{
		AccountCurrency:'帳戶貨幣',
		Recharge:'充值',
		BasicsROOM:'基礎理財',
		GrandmasterROOM:'大師理財',
		expertROOM:'專家理財',
		siperVIP:'超级VIP',
		Singleminimumpurchase:'單筆最低買入',
		Entertheminimumamount:'進入最低金額',
		Numberoftransactions:'交易人數',
		people:'人'
	},
	jiaoyizhongxin:{
		TransactionHistory:'交易記錄',
		v2_until:'截止',
		v2_getting:'正在獲取',
		User:'用戶',
		Numberofrounds:'輪數',
		TradeCategory:'交易品類',
		SingleRoundTrading:'單輪交易',
		Operation:'操作',
		v2_changui:'常規',
		v2_teshu:'特殊',
		v2_shuwei:'数位',
		v2_duo:'做多',
        v2_kong:"做空",
		v2_dan:'做單',
		v2_shuang:'做雙',
		v2_jiyang:"極陽",
        v2_jiyin:"極陰",
		v2_teshu_4:'豹子通选',
		v2_teshu_5:'顺子通选',
		v2_teshu_6:'对子通选',
		v2_1:'百十高',
		v2_2:'百十低',
		v2_3:'百个高',
		v2_4:'百个低',
		v2_5:'十个高',
		v2_6:'十个低',
		Buy:'購入',
		gendanbt:'跟單',
		di:'第',
		lun:'轮',
		alertqdgd:'確定跟單？',
		queding: "確定",
		quxiao: "取消",
		v2_duodan:"多單",
        v2_kongdan:"空單",
        v2_duoshuang:"多雙",
        v2_kongshuang:"空雙"
	},
	tabbar:{
		Home:'首頁',
		Recharge:'充值',
		Trend:'走势',
		My:'我的',
		kefu:'客服'
	},
	securityCenter: {

	},
	srdlmm:'輸入登錄密碼',
	tixianmima: "提現密碼",
	denglumima: "登入密碼",
	zhong: "中",
	anquandengji: "安全等級",
	anquanzhongxin: "安全中心",
	zhanghaowuyanzhengfangshi: "帳號無驗證管道，忘記舊密碼請",
	lianxikefu: "聯系客服",
	xindenglumimaguize: "新登入密碼請輸入6-16比特數位和字母的組合",
	xinmima: "新密碼",
	querenxinmima: "確認新密碼",
	jiumima: "舊密碼",
	xiugaidenglumima: "修改登入密碼",
	shurujiumima: "請輸入舊密碼",
	xiugaitixianmima: "修改提現密碼",
	qsrtxmm:'請輸入提現密碼',
	yuanmimabuyongshuru: "原密碼、第一次設定不用輸入",
	qingshuruxinmima: "請輸入你的新密碼",
	queding: "確定",
	tianjiayinhangka: "添加銀行卡",
	profit:{
		biaoti:'投資理財',
		name:'7天財務管理',
		jine:'金額',
		shouru:'收入',
		cunkuanshijian:'存款時間',
		daoqi:'到期時間',
		cunkuanjine:'存款金額',
		cunkuan:'立即存款',
		shurujine:'輸入金額',
		goumai:'購買',
		quxiao:'取消',
	},
	fund:{
		biaoti:'資金明細',
		tikuan:'提款',
		zhuangtai:'狀態',
		shijian:'時間',
		nomore:'沒有更多資訊',
	},
	txmoney:{
		Withdrawcash:'提現',
		AmountofCashWithdrawal:'可提現金額',
		Withdrawcashamount:'提現金額',
		WithdrawPassword:'提現密碼',
		Pleaseenterthewithdrawalamount:'請輸入提現金額',
		Pleaseenteryourwithdrawalcode:'請輸入提現密碼'
	},
	touzijilu:{
		biaoti:'投資記錄',
		lunshu:'輪數',
		danbijine:'單筆投資金額',
		huoli:'獲利金額',
		leixing:'交易類型',
		zhuangyai:'狀態',
		shijian:'時間',
		Pendingearnings:'待收益',
		Investmentsuccess:'投資成功',
		investmentfailure:'投資失敗'
	},
	game:{
		game1:'保護海洋',
		game2:'距離',
		game3:'期',
		game4:'截止',
		game5:'小',
		game6:'雙',
		game7:'總和',
		game8:'攤路：',
		game9:'開獎記錄',
		game10:'走勢圖',
		game11:'大小走勢',
		game12:'單雙走勢',
		game13:'大',
		game14:'單',
		game15:'本期已下',
		game16:'注',
		game17:'查看本期下注',
		game18:'查看追號信息',
		game19:'期正在投注中',
		game20:'追號',
		game21:'設置期數內中獎後自動停止追號，',
		game22:'如果沒有追中，追到設置追號的期數爲止。',
		game23:'期數選擇：',
		game24:'預購',
		game25:'預購總計:',
		game26:'混合',
		game27:'特號',
		game28:'預購',
		game29:'玩法:',
		game30:'大單:',
		game31:'大雙:',
		game32:'小單:',
		game33:'小雙:',
		game34:'極大:',
		game35:'極小:',
		game36:'豹子:',
		game37:'紅波:',
		game38:'綠波:',
		game39:'藍波:',
		game40:'選擇玩法，點擊金額購買:',
		game41:'購買金額:',
		game42:'追號',
		game43:'購買',
		game44:'重置:',
		game45:'返回:',
		game46:'買入:',
	},
	kefu:{
		title:'在線客服',
		t1:'聯系我們',
		q1:'1、如何充值？',
		q1a:'聯系客服，銀行卡轉賬',
		q2:'2、忘記了密碼怎麽辦？',
		q2a:'請及時提供相應信息給客服，系統將重置一個新密碼，爲保障賬戶安全，建議用戶登錄後立即修改密碼。',
		q3:'3、如何開通網上支付？',
		q3a:'開通銀行卡網上支付功能，包括網上開通和現場開通兩種方式：',
		q3b:'（1）網上開通：登錄各大銀行的官方網站在線開通。',
		q3c:'（2）現場開通：前往各大銀行的營業網點現場開通。',
		q4:'4、提款次數有沒有限制？',
		q4a:'本公司不限制提款次數。',
		q5:'5、提款需要手續費嗎？',
		q5a:'達到提款條件才能提款，提款不限次數，手續費根據本站設定。提款時産生的銀行手續費將全部由本站爲您承擔。',
		q6:'6、哪裏可以查到公司相關信息？',
		q6a:'公司官方網站地址：',
		kfxt:'客服小T',
		t001:'您好！ 智能客服為你服務～',
	},
	my:{
		t1:'余額',
		t2:'錢包',
		t3:'流水',
		t4:'賬戶資料',
		t5:'修改密碼',
		t6:'設置資金密碼',
		t7:'退出登錄',
	},
	wallet: {
		t1: '賬號余額',
		t2: '可用余額',
		t3: '凍結金額',
		t4: '充值',
		t5: '提現',
		t6: '銀行卡',
		t7: '交易記錄',
	},
	cash:{
		t1:'提款申請',
		t2:'最多可提現：',
		t3:'資金密碼',
		t4:'單筆',
		t5:'手續費',
		t6:'實到帳',
		t7:'姓名',
		t8:'銀行名稱',
		t9:'銀行賬號',
		t10:'您可以備注一段內容給管理員',
	},
	cardInfo:{
		t1:'銀行卡管理',
		t2:'我的銀行卡',
		t3:'如您銀行卡被限制，請聯系客服',
		t4:'溫馨提示：',
		t5:'1. 只能綁定一張銀行卡',
		t6:'2. 綁定後可使用該卡提現',
		t7:'我要提現 ',
	},
	recharge:{
		t1:'賬戶充值',
		t2:'充值金額',
		t3:'最低充值金額$100',
		t4:'到帳賬號',
		t5:'支付方式',
		t6:'充值彙率',
		t7:'提示',
		t8:'1、請確認您的賬號。最低充值金額爲：$100',
		t9:'2、請按照上面提供的支付方式選擇進行支付',
		t10:'3、支付時，請備注您支付的訂單號提交審核。提交成功後，請等待到帳確認',
	},
	common_text:{
		tj:'提交',
		RM:'英磅',
		EUR:'歐元',
		USD:'美元',
		JPY:'日元',
	},
	singing:{
		logo_title: "愛心奉獻",
        title1: "感謝每一位持續捐贈超過18個月的益童堅持者!",
        title2: "捐贈",
        title3: "助力金",
        title4: "我的累計慈善捐款",
        title5: "可捐贈金額",
        title6: "愛心奉獻",
        title7: " 在經營可持續及成功業務的同時，慈善助力金亦不忘為我們營運所在的國家，社區，帶來正面影響，慈善助力金積極支持國家，社區，發展及成長，並透過參與慈善活動履行企業社會責任，為下一代建設更美好的將來，秉承這一承諾，慈善助力金成立助力，旨在回饋社會，助力的運作獨立於慈善助力金，並擁有其信託董事會，專注於舉辦培養年輕人積極的人生觀、教育和就業的慈善活動，助力冀幫助下一代提高生活水平，建立一個更包容和諧的環境，鼓勵年輕人成為負責任的社會公民，展望未來，慈善助力金將繼續支持，推動建設更包容和諧的社會，發揚人道與博愛精神的項目，愛心服事貧苦及有需要幫助的人們，與他們一起面對貧窮和災難，合力克服困境，渡過難關，本集團特別關心兒童的處境與福樂，關注性別平等和環境保護問題，至今本集團已捐贈數千萬助力金，捐贈的資金持續增長中，您的每一次援助都會幫助一個遠方處於困境的人，目前本集團主要助力活動包括。",
        title8: "希望小學計劃",
        title9: "2、非洲兒童，旨在幫助非洲兒童解決最低生活需要，包括捐贈食物，藥品，抗瘧疾疫苗和蚊帳等其他生活物資，2019冠狀病毒病大流行引發了一場全球危機，顛覆了非洲各地兒童的生活，長久以來存在的不平等現象，讓生活在最貧困國家和社區的兒童遭到了最嚴重的打擊，本集團的非洲兒童助力金在持續累計中。",
        title10: "聽障兒童",
        title11: " 3、自啟動聽障兒童以來，每年參與計劃的聽障兒童均透過此計劃入讀聖若瑟教區中學第五校，與其他幼童一起進入其“融合教室”，首批5名兒童於一位健聽教師和一名聾人教學助理共同授課的融合教室完成3年學習後，於二零二一年七月從幼稚園畢業，鑑於此計劃在過去3年成功試行，澳門教區作為學校的辦學實體，現已將計劃作為持續實踐項目，並會確保計劃得以持續實施，使參加計劃的聽障兒童能夠在學校接受小學教育，並希望能夠接受中學教育，瞭解更多關於聽障兒童發展的知識，瞭解聽障孩子將要經歷哪些成長階段，如何幫助他們實現這些成長和發展，本集團的聽障兒童助力金在持續累計中",
        title12: "每日助力說明",
        title13: "1、會員用戶每日可以領取一次助力金。",
        title14: "2、每日助力是本著回饋社會目的，非盈利性。",
        title15: "3、每日助力獲取的助力金不可提取，且不可交易。",
        title16: "4、每日助力獲取的助力金使用權歸帳號所有者，只能用於慈善捐贈項目，可以通過本集團向相關慈善項目捐贈。",
        title17: "慈善助力金說明",
        title18:  "1、用戶在遊戲中每盈利一次，平台會額外支付中獎金額0.01%，到用戶的捐贈助力金中，金額累計上不封頂。",
        title19: " 2、慈善助力金是本著回饋社會目的，非盈利性，不能用於平台盈利。",
        title20: "3、慈善助力金獲取的捐贈助力金不可提取，且不可交易。",
        title21: "4、慈善助力金獲取的捐贈助力金使用權歸帳號所有者，只能用於慈善捐贈項目，可以通過本集團向相關慈善項目捐贈。",
        title22: "〈加入我們的計劃〉",
        title23: "〈重繪明日夢想〉",
        title24: "〈攜手為兒童〉",
        title25: "希望小學",
        title26: "援助非洲兒童",
        title27: "聽障兒童",
        title28: "捐贈"
	},
	loginpwd_new:{
		title1: "設置資金密碼",
		title2: "舊密碼",
		title3: "舊密碼(初次設置無需填寫)",
		title4: "新安全密碼",
		title5: "新密碼",
		title6: "確認安全密碼",
		title7: "確認密碼",
		title8: "驗證碼",
		title9: "保存",
		title10: "如需幫助,",
		title11: "請聯繫客服",
		title12: "請輸入密碼和驗證碼"
	},


	account:{
		title1: "交易記錄",
		title2: "僅顯示 1 個月的交易記錄，如需更多信息，請聯繫客服獲取",
		title3: "上一頁",
		title4: "下一頁",
		title5: "提現",
		title6: "投注",
		title7: "盈利",
		title8: "充值",
		title9: "今日",
		title10: "昨日",
		title11: "七日",
		title12: "本月"
	},
	Lottery:{
		txt1: "賬戶資料",
		txt2: "姓名",
		txt3: "設置個性昵稱",
		txt4: "保存",
		txt5: "提示",
		txt6: "姓名和收款銀行卡必須一致，否則會提款失敗",
		txt7: "請妥善保管自己的賬戶密碼，不得隨意借予他人使用",
		txt8: "開獎記錄",
		txt9: "刷新",
		txt10: "號碼",
		txt11: "大小",
		txt12: "單雙",
		txt13: "總和",
		txt14: "小",
		txt15: "雙",
		txt16: "暫無數據",
		txt17: "充值抽獎",
		txt18: "您目前沒有抽獎機會",
		txt19: "今日剩余次數",
		txt20: "其他中獎名單",
		txt21: "獎品列表",
		txt22: "規則說明",
		txt23: "紅包抽獎次數每日更新，不累計。",
		txt24: "抽獎激活紅包金額即可領取。",
		txt25: "紅包抽獎最終解釋權歸本公司所有。",
		txt26: "搖獎紅包額度超出1000免啟動額度，根據規定需要激活後方可提現。",
		txt27: "恭喜獲得獎金",
	},
	jzcg:'捐贈成功',
	jztop:'沒有金額可捐贈',
	qdcg:'簽到成功',
	yjqd:'已經簽到',

	mqmyjh:'您目前沒有抽獎機會',
	gdzlxm:'更多助力項目',
	gdtop:'更多助力項目，敬請期待',
	xianzhi:'溫馨提示：金額限制',

	yaoqingma:'邀請碼',
	yiji:'一級',
	erji:'二級',
	sanji:'三級',
	fzcg:'複制成功',
	fzsb:'複制失敗',

	xgdlmm:'修改登錄密碼',

	gxzj:'恭喜您中獎',
	zjjj:'獎金',

	zcmmts:'兩次輸入的密碼不一致',


	newtext1:'每日簽到',
	newtext2:'星期一',
	newtext3:'星期二',
	newtext4:'星期三',
	newtext5:'星期四',
	newtext6:'星期五',
	newtext7:'星期六',
	newtext8:'星期天',
	newtext9:'簽到',

	newtext10:'信息',
	newtext11:'確認買入',
	newtext12:'取消',
	newtext13:'已選擇',
	newtext14:'注玩法，總金額：',

	newtext15:'周大福項鍊',
	newtext16:'電飯煲',
	newtext17:'幸運紅包',
	newtext18:'日本十日遊',
	newtext19:'勞力士手錶',
	newtext20:'幸運紅包',

	newtext21:'輸入驗證碼',

	newtext22:'個人中心',

	newtext23:'用戶中心',
	newtext24:'我的錢包',
	newtext25:'賬戶管理',
	newtext26:'安全保障中',

	newtext27:'類型',
	newtext28:'時間',

	newtext29:'留言備注',
	newtext30:'提款金額',
	
	newtext31:'賬戶余額',
	newtext32:'纪录'
}
