import http from '@/utils/http'

//钱包登录
export const WalletLogin = (param) => {
    return http({
        url: '/Login/walletLogin',
        method: 'post',
        data: param
    })
}

//登录
export const Login = (param) => {
    return http({
        url: '/login/submit',
        method: 'post',
        data: param
    })
}

//注册
export const Registe = (param) => {
    return http({
        url: '/login/upregister',
        method: 'post',
        data: param
    })
}

//验证码
export const verificationCode = (param) => {
    return http({
        url: '/verify/code',
        method: 'get',
        data: param
    })
}

//个人数据
export const Userdata = () => {
    return http({
        url: '/user/center',
        method: 'get'
    })
}

//首页数据
export const initIndex = (data) => {
    return http({
        url: '/index/index',
        method: 'get',
        params:{...data}
    })
};

//资金明细
export const Record = (data) => {
    return http({
        url: '/user/accountRecords',
        method: 'get',
        params:data
    })
};

//质押项目列表
export const Financin = (data) => {
    return http({
        url: '/api/financinlist',
        method: 'get',
		params:data
    })
}

//质押提交
export const SubFinancin = (param) => {
    return http({
        url: '/Mobile/Financing/index',
        method: 'post',
        data: param
    })
}

//修改登录密码
export const UpdatePassword = (param) => {
    return http({
        url: '/User/uppassword',
        method: 'post',
        data: param
    })
}

//修改交易密码
export const UpdateTransactionPassword = (param) => {
    return http({
        url: '/User/uppp',
        method: 'post',
        data: param
    })
}
// 修改登录密码
export const UpdateUserPassword = (param) => {
    return http({
        url: '/User/uppassword',
        method: 'post',
        data: param
    })
}


//获取银行卡信息
export const GetBank = () => {
    return http({
        url: '/User/getbank',
        method: 'get'
    })
}

//修改/添加银行卡
export const UpdateRebank = (param) => {
    return http({
        url: '/User/rebank',
        method: 'post',
        data: param
    })
}

//提现
export const withdrawalApi = (param) => {
    return http({
        url: '/Mobile/Exchange/shenqing',
        method: 'post',
        data: param
    })
}

// 提现个人类型
export const withdrawalTypeApi = () => {
    return http({
        url: '/api/wallet',
        method: 'get'
    })
}
//盘口列表
export const Thblist = (param) => {
    return http({
        url: '/order/thblist',
        method: 'post',
		data: param
    })
}

//下单记录
export const Thborder = (data) => {
    return http({
        url: '/order/thborder',
        method: 'get',
        params: data
    })
}

//下单提交
export const TradeThb = (param) => {
    return http({
        url: '/order/tradeThb',
        method: 'post',
        data: param
    })
}

//提现记录
export const WithdrawalList = (data) => {
    return http({
        url: '/user/withdrawalRecords',
        method: 'get',
        params: data
    })
}

//退出登录
export const Loginout = () => {
    return http({
        url: '/login/loginout',
        method: 'get'
    })
}

//充值提现记录
export const Ctrecords = (data) => {
    return http({
        url: '/user/ctrecords',
        method: 'get',
        params: data
    })
}

//类型列表
export const Thbtype = () => {
    return http({
        url: '/order/thbtype',
        method: 'get'
    })
}

//质押记录
export const GetFinanList = (status) => {
    return http({
        url: '/Mobile/Financing/log',
        method: 'get',
		params: {
		    status: status
		}
    })
}

export const qiandao = () => {
    return http({
        url: '/User/signin',
        method: 'get'
    })
}
export const juanzeng = () => {
    return http({
        url: '/User/contribute',
        method: 'get'
    })
}
// 签到天数展示
export const showqiandao = () => {
    return http({
        url: '/user/weeksignin',
        method: 'get'
    })
}
//账户资料修改
export const upinfo = (param) => {
    return http({
        url: '/User/upinfo',
        method: 'post',
        data: param
    })
}
//获取账户资料
export const accountinfo = () => {
    return http({
        url: '/user/accountinfo',
        method: 'get'
    })
}
//个人中心
export const user_center = () => {
    return http({
        url: '/user/center',
        method: 'get',
    })
}
//抽奖
export const user_draws = () => {
    return http({
        url: '/User/draws',
        method: 'post',
    })
}
//中奖结果展示
export const drawslog = () => {
    return http({
        url: '/index/drawslog',
        method: 'get',
    })
}
//盘口记录
export const thblist = (param) => {
    return http({
        url: '/order/thblist',
        method: 'post',
        data: param
    })
}
// 我的注单
export const myOrderList = (data) => {
    return http({
        url: '/order/thborder',
        method: 'get',
        params:data
    })
}

// vip规则
export const viplist = () => {
    return http({
        url: '/api/viplist',
        method: 'get',
    })
}

//我的团队
export const myteam = () => {
    return http({
        url: '/user/team',
        method: 'get',
    })
}

//奖品列表
export const prizelist = () => {
    return http({
        url: '/index/drawslist',
        method: 'get',
    })
}

//登录状态
export const loginstatus = () => {
    return http({
        url: '/user/loginstate',
        method: 'get',
    })
}
    
//货币列表
export const getallcoinList = (data) => {
    return http({
        url: `/ajax/allcoin_a/id/${data.id}/type/${data.type}`,
        method: 'get',
        params:{coin:data.coin}
    })
}

//K线
export const kxianbData = (data) => {
    return http({
        url: `/ajax/kxianb/name/${data.name}/time/${data.time}`,
        method: 'get'
	})
}
	
// 提现
export const withdrawApi = (data) => {
    return http({
        url: '/Exchange/shenqing',
        method: 'post',
        data: data
    })
}

export const getWalletAccount = () => {
    return http({
        url: '/api/purse',
        method: 'get'
    })
}
// 订单
export const ordersApi = (data) => {
    return http({
        url: '/Mobile/Ajax/getEntrustAndUsercoin1',
        method: 'get',
        params: data
    })
}

// 充值
export const deposit = (data) => {
    return http({
        url: '/Finance/recharge',
        method: 'post',
        data: data
    })
}

// 获取充值类型
export const depositTypeApi = () => {
    return http({
        url:'/api/recharge',
        method:'get'
    })
}

// 账单记录
export const billRecordApi = (data) => {
    return http({
        url:'/user/accountRecords',
        method:'get',
        params:data
    })
}

// 订单记录
export const financingLogApi = (data) => {
    return http({
        url:'/financing/log',
        method:'get',
        params: data
    })
}
// 获取详情
export const financingLogDetailApi = (id) => {
    return http({
        url:`/api/dlog?id=${id}`,
        method:'get',
    })
}


export const faq = () => {
    return http({
        url: '/page/help',
        method: 'get'
    })
}

export const notice = () => {
    return http({
        url: '/page/notice',
        method: 'get'
    })
}

export const invite = () => {
    return http({
        url: '/user/invite',
        method: 'get'
    })
}


export const team = () => {
    return http({
        url: '/user/team',
        method: 'get'
    })
}

//最大杠杆倍数
export const getmultiple = () => {
    return http({
        url: '/api/multiple',
        method: 'get'
    })
}

//获取冻结金额
export const getuserbalances = () => {
    return http({
        url: '/User/balance',
        method: 'get'
    })
}

//规则状态
export const getshowrule = () => {
    return http({
        url: '/user/showrule',
        method: 'get'
    })
}

//发送消息
export const chatsend = (param) => {
    return http({
        url: '/chat/send',
        method: 'post',
        data: param
    })
}

//聊天记录
export const getchatlog = () => {
    return http({
        url: '/chat/log',
        method: 'get'
    })
}

//聊天提醒
export const getchatnotice = () => {
    return http({
        url: '/chat/notice',
        method: 'get'
    })
}

//获取欢迎语
export const getchatwelcome = (id) => {
    return http({
        url:`/page/helpinfo?id=${id}`,
        method:'get',
    })
}

// 钱包唤起充值
export const walletdeposit = (data) => {
    return http({
        url: '/Finance/rechargebywallet',
        method: 'post',
        data: data
    })
}

// 获取钱包类型
export const walletdepositType =() => {
    return http({
        url: '/api/recharge',
        method: 'get'
    })
}

//图片上传公共接口
export const uploadimage = (param) => {
        return http({
            url: '/api/image',
            method: 'post',
            data: param,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
}

//首页获取的三个数据
export const datalist =() => {
    return http({
        url: '/api/datalist',
        method: 'get'
    })
}

//检测贷款状态
export const getloanstatus = () => {
    return http({
        url: '/user/getloan',
        method: 'get'
    })
}

//贷款提交
export const togetloansub = () => {
    return http({
        url: '/user/togetloan',
        method: 'get'
    })
}

//获取弹窗信息
export const getpopupsmsg = (data) => {
    return http({
        url: '/user/msg',
        method: 'get',
        params: data
    })
}