/*简体*/
export default {
    home: {
        PreferentialActivities: 'Preferential Activities',
        Deposit: 'Deposit',
        ProductIntroduction: 'Product Introduction',
        OnlineCustomerService: 'Online Customer Service',
        HotProducts: 'Hot Products',
        Thailand: 'Thailand',
        canada: 'canada',
        Australia: 'Australia',
        NewYork: 'NewYork',
        Singapore: 'Singapore',
        England: 'England',
        Japan: 'Japan',
        India: 'India',

        ljcj:'立即抽奖',
        czcj:'储值抽奖',
        ckgd:'查看更多',
        jzlb:'捐赠列表',
        jcyx:'精彩游戏',
        ax_tips:'你们的长期支持对于推动益童项目的成功发展和影响力的提高都起到了至关重要的作用。这种持续的支持对社会福祉和儿童福利有着积极的影响，为他们提供了更好的生活和教育机会。',
        "768194-0": "体验金",
"768194-1": "简体",
"768194-2": "繁体",
"768194-3": "弘扬慈善传统美德、促进社会文明进步、多多助力购联手打造、在线数字娱乐平台、每日任务最高领取257,760助力金、祝您生活愉快～",
"768194-4": "新加坡大彩",
"768194-5": "4D彩票，玩转大奖",
"768194-6": "进入",
"768194-7": "万字票",
"768194-8": "寻宝大行动",
"768194-9": "多多彩票",
"768194-10": "幸运大奖，等你拿",
"768194-11": "感谢每一位持续捐赠超过18个月的益童坚持者!",
"768194-12": "捐赠",
"768194-13": "钱包",
"768194-14": "爱心奉献",
"985138-0": "登录",
"985138-1": "简",
"985138-2": "繁",
"985138-3": "爱心接力 让心充满希望",
"985138-4": "欢迎光临",
"985138-5": "注册",
"985138-6": "输入账号",
"985138-7": "输入密码",
"985138-8": "立即登录",
"063348-0": "注册",
"063348-1": "简",
"063348-2": "繁",
"063348-3": "爱心接力 让心充满希望",
"063348-4": "欢迎光临",
"063348-5": "登录",
"063348-6": "注册",
"063348-7": "请输入6~20位字母、数字或组合",
"063348-8": "请输入6~16位的字母、数字组合",
"063348-9": "请输入邀请码",
"063348-10": "请填写您的代理人邀请码",
"063348-11": "我已年满18周岁且同意接受",
"063348-12": "注册条款",
"063348-13": "马上注册",
"063348-80":"请输入确认密码"
    },
    tab2: {
        InvestmentRecord: 'Financial Record',
        title1: "Ušün Vanguard",
        title2: "BlackRock AeokAs La: aonuầuusẽưna:Isuño?-Blockdit tũuuwacwasśu aÍnнรัuนัnอ่าuแล:นักเปียนทีมีผู้ชังาบ1ล้านคบลองใช้แพลตฟอร์มนีเพือได้ไอเดียใหม่ๆแล้วอาจพบว่าสังคมนีเหมาะกั บคนเช่นคุณ.",
        title3: "-Blockdit tũuuwacwasśu aÍnнรัuนัnอ่าu แล:นักเปียนทีมีผู้ชังาบ1ล้านคบลองใช้แพลตฟอร์มนีเพือได้ไอเดียใหม่ๆแล้วอาจพบว่าสังคมนีเหมาะกั บคนเช่นคุณ.",
        title4: "-BlackRock is an investment management company based in the United States, founded in 1988 by a group of investors and businessmen. It has grown to become one of the largest investment management companies in the world, with assets under management of $234 billion. BlackRock provides investment services to clients in over 100 countries and is listed on the S&P 500 index as “BLK”.",
        title5: "It raised capital in",
        title6: "the New York Stock Exchange in 1999. The total assets under management at the time of its founding were $4.9 billion, which has since increased to $234 billion, or nearly 48 times. BlackRock is the largest investment management company in the world when measured by assets under management (AUM).",
        title7: "1. BlackRock has assets under management valued at $234 billion",
        title8: "2. Vanguard Group has assets under management valued at $183 billion",
        title9: "3. UBS Group AG has assets under management valued at $105 billion. It is an investment management company that receives investment from clients under the management of BlackRock.",
        title10: "- Aqu 52%",
        title11: "-cnsnansHüB1%",
        title12: "- tōuao 7%",
        title13: "- ãunšwúóu 9 10%",
        title14: "While following the group of clients from the assets under management of BlackRock",
        title15: "-70% are clients in North America",
        title16: "- 25% are clients in Europe and Africa",
        title17: "-5% are clients in Asia",
        title18: "In summary, BlackRock focuses on investing in stocks and its main clients are in North America, while currently providing services to investors in over 100 countries worldwide. BlackRock is also one of the companies included in the S&P 500 index as “BLK”.",
        title19: "Holding a major stake in companies",
        title20: "BlackRock holds a major stake in several global companies, such as",
        title21: "- Holding 1,069 million shares of Apple, valued at $4.1 trillion",
        title22: "- Holding 516 million shares of Microsoft, valued at $3.4 trillion",
        title23: "- Holding 27 million shares of Amazon.com, valued at $2.6 trillion",
        title24: "- Holding 158 million shares of Facebook, valued at $1.3 trillion",
        title25: "In the case of Apple, BlackRock is one of the top 2 largest shareholders, making one of the co-founders of BlackRock a member of Apple's board of directors. Additionally, BlackRock's performance has been as follows:",
        title26: "In 2017, it had a revenue of $375 billion and a net income of $149 billion",
        title27: "In 2018, it had a revenue of $426 billion and a net income of $129 billion",
        title28: "In 2019, it had a revenue of $463 billion and a net income of $135 billion",
        title29: "As of the end of the fiscal year, the market value of BlackRock's assets was $73.2 trillion",
        title30: "It manages more than $21 trillion, up from $1.1 trillion in 2010",
        title31: "BlackRock's profit surpasses that of commercial banks."

    },
    tab4: {
        Loginnow: 'Log in now',
        RegisterNow: 'Register Now',
        Withdrawcash: 'Withdraw cash',
        deposit: 'deposit',
        profitAndLoss: "Today's profit and loss",
        ProductIntroduction: 'Product Introduction',
        FundDetails: 'Fund Details',
        InvestmentRecord: 'Investment Record',
        PreferentialActivities: 'Preferential Activities',
        SecurityCenter: 'Security Center',
        Contactcustomerservice: 'Contact customer service',
        Setup: 'Setup',
        shezhiyhk: 'Please set up a bank card first',
        shezhitxmm: 'Please set a withdrawal password first'
    },
    login: {
        Loginnow: 'Log in now',
        QuickRegistration: 'Quick Registration',
        Pleaseenteryourusernamephonenumber: 'Please enter your username/phone number',
        Pleaseenterapassword: 'Please enter a password',

    },
    registe: {
        RegisterNow: 'Register Now',
        Logintoanexistingaccount: 'Login to an existing account',
    },
    shezhi: {
        languagesettings: 'language settings',
        Switchaccount: 'Switch account'
    },
    yuyanshezhi: {
        languagesettings: 'language settings'
    },
    editbank: {
        Addabankcard: 'Add a bank card',
        RealName: 'Real Name',
        BankCardNumber: 'Bank Card Number',
        BankAddress: 'Bank Address',
        Pleaseenteryourrealname: 'Please enter your realname',
        Pleaseenteryourbankcardnumber: 'Please enter your bank card number',
        Pleaseenterthebankaddress: 'Please enter the bank address'
    },
    remen: {
        AccountCurrency: 'Account Currency',
        Recharge: 'Recharge',
        BasicsROOM: 'Basics ROOM',
        GrandmasterROOM: 'Grandmaster ROOM',
        expertROOM: 'expert ROOM',
        siperVIP: 'siper VIP',
        Singleminimumpurchase: 'Single minimum purchase',
        Entertheminimumamount: 'Enter the minimum amount',
        Numberoftransactions: 'Number of transactions',
        people: 'people'
    },
    jiaoyizhongxin:{
        TransactionHistory: '交易历史',
        v2_until: '至',
        v2_getting: '买入',
        User: '用户',
        Numberofrounds: '轮次数',
        TradeCategory: '交易类别',
        SingleRoundTrading: '单轮交易',
        Operation: '操作',
        v2_changui: '常规',
        v2_teshu: '特殊',
        v2_shuwei: '数尾',
        v2_duo: '多',
        v2_kong: "空",
        v2_dan: '单',
        v2_shuang: '双',
        v2_jiyang: "涨",
        v2_jiyin: "跌",
        v2_teshu_4: '虎星大选',
        v2_teshu_5: '直选',
        v2_teshu_6: '组选',
        v2_1: '110',
        v2_2: '低一百高一百',
        v2_3: '百高',
        v2_4: '百最低级别',
        v2_5: '十高',
        v2_6: '十低',
        Buy: '买入',
        gendanbt: '跟单',
        di: '第',
        lun: '轮',
        alertqdgd: '确定要清空股单？',
        queding: "确定",
        quxiao: "取消",
        v2_duodan: "多单",
        v2_kongdan: "空单",
        v2_duoshuang: "多双",
        v2_kongshuang: "空双"
        },
    tabbar: {
        Home: '首页',
        Recharge: '充值',
        Trend: '走势',
        My: '我的',
		kefu:'客服'
    },
    srdlmm: 'Enter login password',
    tixianmima: "Withdraw Password",
    denglumima: "Login Password",
    zhong: "中",
    anquandengji: "Security Level",
    anquanzhongxin: "Security Center",
    zhanghaowuyanzhengfangshi: "The account has no verification method, please forget the old password",
    lianxikefu: "Contact customer service",
    xindenglumimaguize: "Please enter a combination of 6-16 digits and letters for the new login password",
    xinmima: "New Password",
    querenxinmima: "Confirm new password",
    jiumima: "Old password",
    xiugaidenglumima: "Modify login password",
    shurujiumima: "Please enter the old password",
    xiugaitixianmima: "Modify withdrawal password",
    yuanmimabuyongshuru: "Original password, no need to enter the first setting",
    qingshuruxinmima: "Please enter your new password",
    queding: "OK",
    tianjiayinhangka: "Add a bank card",
    profit: {
        biaoti: 'Preferential Activities',
        name: '7วัน Financial Management',
        jine: 'amount',
        shouru: 'income',
        cunkuanshijian: 'Deposit time',
        daoqi: 'Expire date',
        cunkuanjine: 'Deposit amount',
        cunkuan: 'Deposit now',
        shurujine: 'Enter amount',
        goumai: 'Buy',
        quxiao: 'Cancel',
    },
    fund: {
        biaoti: 'Fund details',
        tikuan: 'Withdraw money',
        zhuangtai: 'state',
        shijian: 'time',
        nomore: 'No more information',
    },
    txmoney: {
        Withdrawcash: 'Withdraw cash',
        AmountofCashWithdrawal: 'Amount of Cash Withdrawal',
        Withdrawcashamount: 'Withdraw cash amount',
        WithdrawPassword: 'Withdraw Password',
        Pleaseenterthewithdrawalamount: 'Please enter the withdrawal amount',
        Pleaseenteryourwithdrawalcode: 'Please enter your withdrawal code'
    },
    touzijilu: {
        biaoti: 'Investment Record',
        lunshu: 'Number of rounds',
        danbijine: 'Single investment amount',
        huoli: 'Profit amount',
        leixing: 'Transaction Type',
        zhuangyai: 'State',
        shijian: 'time',
        Pendingearnings: 'Pending earnings',
        Investmentsuccess: 'Investment success',
        investmentfailure: 'investment failure'
    },
    game: {
        game1: '保护海洋',
        game2: '距离',
        game3: '轮次',
        game4: '截止时间',
        game5: '小',
        game6: '偶数',
        game7: '和值',
        game8: '平局：',
        game9: '开奖结果',
        game10: '走势图',
        game11: '大小走势',
        game12: '单双走势',
        game13: '大',
        game14: '单',
        game15: '本轮投注数量',
        game16: '注',
        game17: '查看本轮投注',
        game18: '查看追踪信息',
        game19: '第几轮投注进行中',
        game20: '追踪',
        game21: '设置在指定轮次内中奖后自动停止追踪',
        game22: '如果没有中奖，在设定的轮次后停止追踪',
        game23: '选择轮次数量:',
        game24: '预购',
        game25: '预购总额:',
        game26: '混合',
        game27: '特殊号码',
        game28: '预购',
        game29: '玩法:',
        game30: '大单:',
        game31: '大双:',
        game32: '小单:',
        game33: '小双:',
        game34: '极大:',
        game35: '极小:',
        game36: '三同:',
        game37: '红波:',
        game38: '绿波:',
        game39: '蓝波:',
        game40: '选择玩法，点击购买金额为:',
        game41: '购买金额:',
        game42: '追号:',
        game43: '购买:',
        game44: '重置:',
        game45: '返回:',
        game46: '购入金额:'
    },

    kefu: {
        title: '在线客服',
        t1: '联系我们',
        q1: '1、如何充值？',
        q1a: '联系客服，银行卡转账 ',
        q2: '2、忘记了密码怎么办？',
        q2a: '请及时提供相应信息给客服，系统将重置一个新密码，为保障账户安全，建议用户登录后立即修改密码。',
        q3: '3、如何开通网上支付？',
        q3a: '开通银行卡网上支付功能，包括网上开通和现场开通两种方式：',
        q3b: '（1）网上开通：登录各大银行的官方网站在线开通。',
        q3c: '（2）现场开通：前往各大银行的营业网点现场开通。',
        q4: '4、提款次数有没有限制？',
        q4a: '本公司不限制提款次数。',
        q5: '5、提款需要手续费吗？',
        q5a: '达到提款条件才能提款，提款不限次数，手续费根据本站设定。提款时产生的银行手续费将全部由本站为您承担。',
        q6: '6、哪里可以查到公司相关信息？',
        q6a: '公司官方网站地址：',
        kfxt:'客服小T',
        t001:'您好！智能客服为你服务～',
    },
    my: {
        t1: '余额',
        t2: '钱包',
        t3: '流水',
        t4: '账户资料',
        t5: '修改密码',
        t6: '设置资金密码',
        t7: '退出登录',
    },
    wallet: {
        t1: '账号余额',
        t2: '可用余额',
        t3: '冻结金额',
        t4: '充值',
        t5: '提现',
        t6: '银行卡',
        t7: '交易记录',
    },
    cash:{
        t1:'提款申请',
        t2:'最多可提现：',
        t3:'资金密码',
        t4:'单笔',
        t5:'手续费',
        t6:'实到帐',
        t7:'姓名',
        t8:'银行名称',
        t9:'银行账号',
        t10:'您可以备注一段内容给管理员',
    },
    cardInfo:{
      t1:'银行卡管理',
      t2:'我的银行卡',
      t3:'如您银行卡被限制，请联系客服',
      t4:'温馨提示：',
      t5:'1. 只能绑定一张银行卡',
      t6:'2. 绑定后可使用该卡提现',
      t7:'我要提现 ',
    },
    recharge: {
        t1: '账户充值',
        t2: '充值金额',
        t3: '最低充值金额$100',
        t4: '到帐账号',
        t5: '支付方式',
        t6: '充值汇率',
        t7: '提示',
        t8: '1、请确认您的账号。最低充值金额为：$100',
        t9: '2、请按照上面提供的支付方式选择进行支付',
        t10: '3、支付时，请备注您支付的订单号提交审核。提交成功后，请等待到帐确认',
    },
    common_text: {
        tj: '提交',
        RM: '英镑',
        EUR: '欧元',
        USD: '美元',
        JPY: '日元',
    },
    singing:{
		logo_title: "爱心奉献",
        title1: "感谢每一位持续捐赠超过18个月的益童坚持者!",
        title2: "捐赠",
        title3: "助力金",
        title4: "我的累计慈善捐款",
        title5: "可捐赠金额",
        title6: "爱心奉献",
        title7: " 在经营可持续及成功业务的同时，慈善助力金亦不忘为我们营运所在的国家，社区，带来正面影响，慈善助力金积极支持国家，社区，发展及成长，并透过参与慈善活动履行企业社会责任，为下一代建设更美好的将来，秉承这一承诺，慈善助力金成立助力，旨在回馈社会，助力的运作独立于慈善助力金，并拥有其信託董事会，专注于举办培养年轻人积极的人生观、教育和就业的慈善活动，助力冀帮助下一代提高生活水平，建立一个更包容和谐的环境，鼓励年轻人成为负责任的社会公民，展望未来，慈善助力金将继续支持，推动建设更包容和谐的社会，发扬人道与博爱精神的项目，爱心服事贫苦及有需要帮助的人们，与他们一起面对贫穷和灾难，合力克服困境，渡过难关，本集团特别关心儿童的处境与福乐，关注性别平等和环境保护问题，至今本集团已捐赠数千万助力金，捐赠的资金持续增长中，您的每一次援助都会帮助一个远方处于困境的人，目前本集团主要助力活动包括。",
        title8: "希望小学计划",
        title9: "2、非洲儿童，旨在帮助非洲儿童解决最低生活需要，包括捐赠食物，药品，抗疟疾疫苗和蚊帐等其他生活物资，2019冠状病毒病大流行引发了一场全球危机，颠覆了非洲各地儿童的生活，长久以来存在的不平等现象，让生活在最贫困国家和社区的儿童遭到了最严重的打击，本集团的非洲儿童助力金在持续累计中。",
        title10: "听障儿童",
        title11: " 3、自启动听障儿童以来，每年参与计划的听障儿童均透过此计划入读圣若瑟教区中学第五校，与其他幼童一起进入其“融合教室”，首批5名儿童于一位健听教师和一名聋人教学助理共同授课的融合教室完成3年学习后，于二零二一年七月从幼稚园毕业，鉴于此计划在过去3年成功试行，澳门教区作为学校的办学实体，现已将计划作为持续实践项目，并会确保计划得以持续实施，使参加计划的听障儿童能够在学校接受小学教育，并希望能够接受中学教育，瞭解更多关于听障儿童发展的知识，瞭解听障孩子将要经历哪些成长阶段，如何帮助他们实现这些成长和发展，本集团的听障儿童助力金在持续累计中",
        title12: "每日助力说明",
        title13: "1、会员用户每日可以领取一次助力金。",
        title14: "2、每日助力是本着回馈社会目的，非盈利性。",
        title15: "3、每日助力获取的助力金不可提取，且不可交易。",
        title16: "4、每日助力获取的助力金使用权归账号所有者，只能用于慈善捐赠项目，可以通过本集团向相关慈善项目捐赠。",
        title17: "慈善助力金说明",
        title18:  "1、用户在游戏中每盈利一次，平台会额外支付中奖金额0.01%，到用户的捐赠助力金中，金额累计上不封顶。",
        title19: " 2、慈善助力金是本着回馈社会目的，非盈利性，不能用于平台盈利。",
        title20: "3、慈善助力金获取的捐赠助力金不可提取，且不可交易。",
        title21: "4、慈善助力金获取的捐赠助力金使用权归账号所有者，只能用于慈善捐赠项目，可以通过本集团向相关慈善项目捐赠。",
        title22: "〈加入我们的计划〉",
        title23: "〈重绘明日梦想〉",
        title24: "〈携手为儿童〉",
        title25: "希望小学",
        title26: "援助非洲儿童",
        title27: "听障儿童",
        title28: "捐赠"
	},
    loginpwd_new:{
        title1: "设置资金密码",
        title2: "旧密码",
        title3: "旧密码(初次设置无需填写)",
        title4: "新安全密码",
        title5: "新密码",
        title6: "确认安全密码",
        title7: "确认密码",
        title8: "验证码",
        title9: "保存",
        title10: "如需帮助,",
        title11: "请联系客服",
        title12: "请输入密码和验证码"
    },
    account:{
		title1: "交易记录",
		title2: "仅显示 1 个月的交易记录，如需更多信息，请联系客服获取",
		title3: "上一页",
		title4: "下一页",
		title5: "提现",
		title6: "投注",
		title7: "盈利",
		title8: "充值",
		title9: "今日",
		title10: "昨日",
		title11: "七日",
		title12: "本月"
	},
    Lottery:{
        txt1: "账户资料",
        txt2: "姓名",
        txt3: "设置个性昵称",
        txt4: "保存",
        txt5: "提示",
        txt6: "姓名和收款银行卡必须一致，否则会提款失败",
        txt7: "请妥善保管自己的账户密码，不得随意借予他人使用",
        txt8: "开奖记录",
        txt9: "刷新",
        txt10: "号码",
        txt11: "大小",
        txt12: "单双",
        txt13: "总和",
        txt14: "小",
        txt15: "双",
        txt16: "暂无数据",
        txt17: "充值抽奖",
        txt18: "您目前没有抽奖机会",
        txt19: "今日剩余次数",
        txt20: "其他中奖名单",
        txt21: "奖品列表",
        txt22: "规则说明",
        txt23: "红包抽奖次数每日更新，不累计。",
        txt24: "抽奖激活红包金额即可领取。",
        txt25: "红包抽奖最终解释权归本公司所有。",
        txt26: "摇奖红包额度超出1000免启动额度，根据规定需要激活后方可提现。",
        txt27: "恭喜获得奖金",
    },
	jzcg:'捐赠成功',
	jztop:'没有金额可捐赠',
	qdcg:'签到成功',
	yjqd:'已经签到',

	mqmyjh:'您目前没有抽奖机会',
	gdzlxm:'更多助力项目',
	gdtop:'更多助力项目，敬请期待',
	xianzhi:'温馨提示：金额限制',

	yaoqingma:'邀请码',
	yiji:'一级',
	erji:'二级',
	sanji:'三级',
	fzcg:'复制成功',
	fzsb:'复制失败',

	xgdlmm:'修改登录密码',
	gxzj:'恭喜您中奖',
	zjjj:'奖金',

	zcmmts:'两次输入的密码不一致',


	newtext1:'每日签到',
	newtext2:'星期一',
	newtext3:'星期二',
	newtext4:'星期三',
	newtext5:'星期四',
	newtext6:'星期五',
	newtext7:'星期六',
	newtext8:'星期天',
	newtext9:'签到',

	newtext10:'信息',
	newtext11:'确认买入',
	newtext12:'取消',
	newtext13:'已选择',
	newtext14:'注玩法，总金额：',

	newtext15:'周大福项鍊',
	newtext16:'电饭煲',
	newtext17:'幸运红包',
	newtext18:'日本十日游',
	newtext19:'劳力士手錶',
	newtext20:'幸运红包',

	newtext21:'输入验证码',

	newtext22:'个人中心',

	newtext23:'用户中心',
	newtext24:'我的钱包',
	newtext25:'账户管理',
	newtext26:'安全保障中',

	newtext27:'类型',
	newtext28:'时间',

	newtext29:'留言备注',
	newtext30:'提款金额',
	
	newtext31:'账户余额',
    newtext32: '记录'

}
