<template>
    <div class="container">

        <div class="my_money_info_new">
            <div class="top-title">{{$t('my.t2')}}</div>

            <div class="wallet_lump">
                <div class="balance" style="    font-size: 14px;">
                    <img src="@/assets/images/tab4/icon_11.png"
                         style="margin-right: 7px; position: relative;  top: -3px;">
                    {{$t('wallet.t1')}}
                </div>
                <div class="number"><span id="yue" data-value="42432.94">{{userblank}}</span></div>
                <div class="amount_lump">
                <span>
                    <span style="color:#656565"> {{$t('wallet.t2')}}</span>
                    <span>{{userblank}}</span>
                </span>
                    <span>
                    <span style="color:#656565"> {{$t('wallet.t3')}}</span>
                    <span>{{lockedVal}}</span>
                </span>
                </div>
            </div>

        </div>

        <div class="t1-t2-box" style="   ">
            <div class="t1-div" style=" " @click="jumpUrl('recharge')">
                <div>
                    <img style="width:40px;height:40px;    margin-right: 0.1rem" class="pic" src="@/assets/images/tab4/003.png" alt="PIC">
                </div>
                <div class="tsub-text"> {{$t('wallet.t4')}}</div>
            </div>
            <div class="t2-div" style="" @click="jumpUrl('cash')">
                <div>
                    <img style="width:40px;height:40px;    margin-right: 0.1rem"  class="pic" src="@/assets/images/tab4/004.png" alt="PIC">
                </div>
                <div class="tsub-text"> {{$t('wallet.t5')}}</div>
            </div>
            <div class="t3-div" style="" @click="jumpUrl('card')">
                <div>
                    <img style="width:40px;height:40px;    margin-right: 0.1rem"  class="pic" src="@/assets/images/tab4/icon_bankcard2.png" alt="PIC">
                </div>
                <div class="tsub-text"> {{$t('wallet.t6')}}</div>
            </div>
        </div>


        <div class="my_item_div">

            <div style="border-left: medium none;" @click="jumpUrl('account')">
                 <span class="ele">
                      <span><img src="@/assets/images/tab4/icon_log2.png?v=1707487920"> {{$t('wallet.t7')}}</span>
                       <img src="@/assets/images/tab4/icon_right_arrow.png">
                 </span>
            </div>

            <div style="border-left: medium none;" @click="jumpUrl('user_money_pwd')">
                 <span class="ele">
                      <span><img src="@/assets/images/tab4/icon_setting.png?v=1707487920">{{$t('my.t6')}}</span>
                       <img src="@/assets/images/tab4/icon_right_arrow.png">
                 </span>
            </div>

            <div style="border-left: medium none;" @click="jumpUrl('singing')">
                 <span class="ele">
                      <span><img src="@/assets/images/tab4/icon_boosting.png?v=1707487920">Singapore Lottery</span>
                       <img src="@/assets/images/tab4/icon_right_arrow.png">
                 </span>
            </div>

        </div>

    </div>
</template>

<script>
    // 功能引入
    import {
        toRefs,
        reactive,
        onMounted,
        onUnmounted
    } from 'vue'
    import {
        useRouter,
        useRoute
    } from 'vue-router'
    import {showToast, showConfirmDialog} from 'vant';
    import {
        useI18n
    } from "vue-i18n";
    import {Userdata, initIndex} from '@/api/api'
    import log from "echarts/src/scale/Log";

    import Top from '@/components/Top'

    export default {
        components: {
            Top
        },
        setup() {

            const router = useRouter()
            const route = useRoute()
            const {
                t
            } = useI18n()
            // 数据
            const State = reactive({
                showMoney: false,
                loginshow: false,
                userid: '',
                username: '',
                userblank: '',
                lockedVal: '',//冻结金额
                userprofit: '',
                UserBank: 0,
                paypasswords: 0
            });

            // 方法
            const Function = reactive({
                jumpUrl(url){
                    if (url){
                        router.push({
                            path: "/"+url
                        });
                    }

                  // alert('点击了')
                },
                onload() {
                    //console.log(sessionStorage.getItem('username'))
                    if (sessionStorage.getItem('username')) {
                        State.loginshow = true
                        State.username = sessionStorage.getItem('username')
                    }
                    Userdata().then(res => {
                        State.userblank = Number(res.data.url.banlance).toFixed(2)
                        State.lockedVal = Number(res.data.url.locked).toFixed(2)
                        State.userprofit = Number(res.data.url.today_profit).toFixed(2)
                        State.UserBank = res.data.url.UserBank
                        State.paypasswords = res.data.url.paypasswords
                        console.log('账户信息---->>',res.data.url)
                    }).catch((error) => {
                        //console.log(error)
                        showToast(error.message)
                    })
                    initIndex().then(res => {
                        //State.userid = '1' + res.data.url.userid
                        State.userid = res.data.url.userid
                        //console.log(res.data)
                    }).catch((error) => {
                        //console.log(error)
                        showToast(error.message)
                    })
                },
                gotologin() {
                    router.push({
                        path: "/login"
                    });
                },
                gotoregister() {
                    router.push({
                        path: "/registe"
                    });
                },
                gotoshezhi() {
                    router.push({
                        path: "/shezhi"
                    });
                },
                gotoFund() {
                    router.push({
                        path: "/fund"
                    });
                },
                gototouzijilu() {
                    router.push({
                        path: "/touzijilu"
                    });
                },
                gotoprofit() {
                    router.push({
                        path: "/profit"
                    });
                },
                gotosecuritycenter() {
                    router.push({
                        path: "/securityCenter"
                    });
                },
                gotoblockrock() {
                    router.push({
                        path: "/blackRock"
                    })
                },
                gototxmoney() {
                    console.log(State.UserBank)
                    //console.log(State.UserBank)
                    //console.log(State.paypasswords)
                    //return false
                    if (State.UserBank == 1 && State.paypasswords == 1) {

                        router.push({
                            path: "/txmoney"
                        })
                    } else if (State.UserBank == 0) {
                        showConfirmDialog({
                            title: '',
                            message: t('tab4.shezhiyhk'),
                            confirmButtonText: t('jiaoyizhongxin.queding'),
                            cancelButtonText: t('jiaoyizhongxin.quxiao'),
                            confirmButtonColor: "#96151D"
                        }).then(() => {
                            router.push({
                                path: "/editbank"
                            })
                        }).catch(() => {

                        });
                    } else if (State.paypasswords == 0) {
                        showConfirmDialog({
                            title: '',
                            message: t('tab4.shezhitxmm'),
                            confirmButtonText: t('jiaoyizhongxin.queding'),
                            cancelButtonText: t('jiaoyizhongxin.quxiao'),
                            confirmButtonColor: "#96151D"
                        }).then(() => {
                            router.push({
                                path: "/bankPwd"
                            })
                        }).catch(() => {

                        });
                    }
                },
                chanpingjieshao() {
                    router.push({
                        path: "/chanpingjieshao"
                    })
                },
                gototixian() {
                    router.push({
                        path: "/tixian"
                    })
                }
            });
            // 接口
            const Interface = reactive({});

            onMounted(() => {
                Function.onload()

            });
            onUnmounted(() => {
                //销毁
            });
            return {
                ...toRefs(State),
                ...toRefs(Function),
                ...toRefs(Interface),
            }
        },
    }
</script>
<style scoped>
    .my-user{
        display: flex;
        align-items: center;
        /*justify-content: space-around;*/
        display: grid;
        grid-template-columns: 1.5rem auto auto;
    }
    .my-user >div >img {
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        margin: 0 auto;
        margin-top: 0.2rem;
        border-radius: 50%;
    }
    .my-user h3 {
        font-weight: normal;
        margin-bottom: 0.15rem;
    }
    .my-user h3 >img {
        display: inline-block;
        width: 0.35rem;
        height: auto;
        margin-left: 0.2rem;
    }

    .t1-t2-box {
        padding-top: 25px;
        padding-bottom: 25px;
        background: white;
        border-bottom: 4px solid #eaeaea;
        height: 110px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        .tsub-text{
            position: relative;
            margin-top: 8px;
        }
    }
    .t1-div {
        font-size: 14px;
        border-right: 1px solid #E1B47D;
        text-align: center;
        /*color: #7B5C2E;*/
        /*font-weight: bold;*/
        color: #909090;
        width: 33%;
        height: 100%;
        /*display: flex;*/
        align-items: center;
        justify-content: center;
    }

    .t2-div {
        width: 33%;
        height: 100%;
        /*display: flex;*/
        align-items: center;
        justify-content: center;
        font-size: 14px;
        text-align: center;
        /*color: #7B5C2E;*/
        /*font-weight: bold;*/
        border-right: 1px solid #E1B47D;
        color: #909090;
    }
    .t3-div {
        width: 33%;
        height: 100%;
        /*display: flex;*/
        align-items: center;
        justify-content: center;
        font-size: 14px;
        text-align: center;
        /*color: #7B5C2E;*/
        /*font-weight: bold;*/
        color: #909090;
    }
</style>
<style lang="scss" scoped>
    .container {
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0;
        background: white;

        .my_item_v2{
            margin: 15px auto;
        }
        .my_money_info_new {
            height: 156px;
            width: 100%;
            background: url(~@/assets/images/tab4/mine_bg.png) no-repeat;
            color: white;
            background-size: 100% 100%;
            padding-bottom: 50px;
            padding-top: 0;
            background: linear-gradient(276deg, #1B81FC 10.53%, #2FA2F4 103.51%)!important;
            border-radius: 0 0 0.5rem 0.5rem;

            .top-title {
                height: 46px;
                color: white;
                text-align: center;
                font-size: 18px;
                line-height: 46px;
            }
            .wallet_lump{
                text-align: center;
                position: relative;
                /*top: 1rem;*/
                width: 92%;
                height: 3rem;
                margin: 0 auto;
                line-height: .98rem;
                font-size: .36rem;
                color: #555;
                text-align: center;
                background: url(~@/assets/images/tab4/wallet_bg.png?v=6) no-repeat;
                background-size: 100%;
                .number {
                    display: block;
                    text-align: center;
                    font-size: .53rem;
                    padding: 0;
                    margin: 0;
                    color: #055986;
                }
                .amount_lump{
                    display: grid;
                    grid-template-columns: 50% 50%;
                    color: #656565;
                    font-size: .3rem;
                    line-height: .4rem;
                    margin-top: 0.1rem;

                    span {
                        display: block;
                        color: #FF875F;
                        font-size: .3rem;
                    }
                }
            }
        }
    }

    #User {
        width: 100%;
        height: 100%;
        position: relative;
    }

    #User a {
        color: #333;
        width: 100%;
        height: 100%;
        display: block;
    }

    #User a:link {
        color: #333;
    }

    #User .iconfont {
        display: inline-block;
        color: #ffffff;
        margin-right: 0.2rem;
        font-size: 0.3rem;
        text-align: center;
    }

    #User .icontixian1 {
        font-size: 0.38rem;
    }

    #User .iconbaobiao {
        font-size: 0.42rem !important;
    }

    #User .head {
        width: 100%;
        position: relative;
        background: #96151D;
        background-size: cover;
    }

    #User .head .luckDrawBtn {
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
        width: 0.82rem;
        height: 0.82rem;
        //background: url(../../xgjt_m/static/img/LuckDrawBtn.4b70089.png) center no-repeat;
        //background-size: cover;
    }

    #User .head .user-h {
        padding-top: 1.09rem;
    }

    #User .head .user-h .user-pic {
        width: 1.51rem;
        height: 1.51rem;
        margin: auto;
        border-radius: 100%;
        position: relative;
    }

    #User .head .user-h .user-pic img {
        width: 100%;
        border-radius: 100%;
    }

    #User .head .user-h .user-pic .grade-icon {
        position: absolute;
        bottom: -0.5rem;
        left: 50%;
        width: 1rem;
        height: 0.5rem;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    #User .head .user-h .link {
        width: 100%;
        height: 0.28rem;
        line-height: 0.28rem;
        margin-top: 0.33rem;
        font-size: 0.3rem;
        text-align: center;
        color: #fff;
    }

    #User .head .user-h .link a {
        color: #fff;
        width: 100%;
        height: 100%;
        display: block;
    }

    #User .head .user-h .link a:link {
        color: #fff;
    }

    #User .head .user-h .link .edite {
        color: #fff;
        margin-left: 0.05rem;
    }

    #User .head .user-h .bg-account {
        margin: 0.47rem 0.2rem 0;
        height: 86px;
        background: #FFEDED;
        color: #222222;
        border-radius: 0.15rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    #User .head .user-h .bg-account .money-item {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    #User .head .user-h .bg-account .money-item.has-line {
        position: relative;
    }

    #User .head .user-h .bg-account .money-item.has-line::after {
        display: block;
        position: absolute;
        content: ' ';
        width: 1px;
        height: .5rem;
        background-color: #987258;
        right: 0;
    }

    #User .head .user-h .bg-account .money-item:last-child::after {
        display: none;
    }

    #User .head .user-h .bg-account .money-item .money {
        font-size: .36rem;
        color: #222222;
    }

    #User .head .user-h .bg-account .money-item .label {
        display: block;
        font-size: .22rem;
        color: #222222;
        margin-top: .04rem;
        text-align: center;
    }

    #User .head .user-h .profile-tab {
        width: 100%;
        box-sizing: border-box;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.2rem;
    }

    #User .head .user-h .profile-tab .t-left {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        padding: 0 0.2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 0.3rem;
        color: #fff8e9;
        position: relative;
    }

    #User .head .user-h .profile-tab .t-left::before {
        content: ' ';
        position: absolute;
        right: 0;
        top: 50%;
        width: 1px;
        height: .3rem;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    #User .head .user-h .profile-tab .t-left:last-child::before {
        display: none;
    }

    #User .head .user-h .profile-tab.no-login .t-left {
        background: RGB(240, 185, 1);
        border-radius: 15px;
        height: .8rem;
        color: #222222;
    }

    #User .head .user-h .profile-tab.no-login .t-left:nth-child(2) {
        margin: 0 0.2rem;
    }

    #User .head .user-h .profile-tab.no-login .t-left::after {
        position: absolute;
        content: ' ';
        width: 0;
        height: .3rem;
        background-color: transparent;
        right: 0;
        top: 0;
    }

    #User .head .user-h .profile-tab.no-login .t-left .iconfont {
        color: #222222;
    }

    #User .head2 .user-h {
        padding-top: 1.09rem;
    }

    #User .trytoplay {
        position: absolute;
        top: 1.27rem;
        right: 0.08rem;
        z-index: 99;
        height: 0.56rem;
        line-height: 0.56rem;
        background-image: -webkit-gradient(linear, left top, right top, from(#ffcb20), color-stop(41%, #f28019), to(linear-gradient(0deg, #ceab77 0%, #eddbc1 100%))), -webkit-gradient(linear, left top, left bottom, from(#018ffd), to(#018ffd));
        background-image: linear-gradient(90deg, #ffcb20 0%, #f28019 41%, linear-gradient(0deg, #ceab77 0%, #eddbc1 100%) 100%), linear-gradient(#018ffd, #018ffd);
        background-blend-mode: normal, normal;
        border-radius: 0.28rem 0px 0px 0.28rem;
    }

    #User .trytoplay a {
        color: #fff;
        font-size: 0.252rem;
    }

    #User .trytoplay a .treg {
        color: #fff;
        margin-right: 0.05rem;
        margin-left: 0.1rem;
        display: inline-block;
    }

    #User .trytoplay a .tnext {
        color: #fff;
        margin-right: 0.05rem;
        display: inline-block;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    #User .account2 {
        width: 6.9rem;
        margin: 0 auto;
        top: 4.2rem;
        left: 0.3rem;
    }

    #User .account2 .tab2 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    #User .account2 .tab2 .t-left {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center;
        background: #fff;
        width: 2.9rem;
        line-height: 0.9rem;
        font-size: 0.36rem;
        -webkit-box-shadow: 0px 3px 12px 0px rgba(146, 133, 238, 0.2);
        box-shadow: 0px 3px 12px 0px rgba(146, 133, 238, 0.2);
        border-radius: 0.2rem;
        margin: 0 0.15rem;
    }

    #User .account2 .tab2 .t-left:first-child {
        border-right: 1px solid #d5d5d5;
    }

    #User .account {
        width: 6.9rem;
        margin: 0 auto;
        background-color: #ffffff;
        -webkit-box-shadow: 0px 3px 12px 0px rgba(146, 133, 238, 0.2);
        box-shadow: 0px 3px 12px 0px rgba(146, 133, 238, 0.2);
        border-radius: 0.2rem;
        position: absolute;
        top: 3.02rem;
        left: 0.3rem;
    }

    #User .account .tab {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0.38rem 0;
        border-bottom: dashed 1px #e5e5e5;
    }

    #User .account .tab .t-left {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        max-width: 33.3%;
        text-align: center;
    }

    #User .account .tab .t-left .money {
        font-size: 0.36rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.24rem;
        letter-spacing: 0px;
        color: #d6a23e;
    }

    #User .account .tab .t-left .size {
        font-size: 0.28rem;
    }

    #User .account .tab .t-left .title {
        margin-top: 0.17rem;
        font-size: 0.252rem;
    }

    #User .account .tab2 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-top: 0.23rem;
        margin-bottom: 0.33rem;
    }

    #User .account .tab2 .t-left {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center;
        font-size: 0.36rem;
        border-right: 1px solid #d5d5d5;
    }

    #User .account .tab2 .t-left:last-child {
        border-right: none;
    }

    #User .new-tablist {
        width: 100%;
        margin-top: 10px;

        .item {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: start;
            padding-top: 10px;
            padding-left: 8%;

            .item-icon {
                width: 10%;

                img {
                    width: 28px;
                    height: 28px;
                }
            }

            .item-title {
                display: block;
                //width: 50%;
                height: 19px;
                margin-top: 5px;
                text-align: left;
                margin-left: 2%;
                font-size: 16px;
                font-family: HarmonyOS Sans, HarmonyOS Sans;
                font-weight: 500;
                color: #000000;
                line-height: 19px;
                //-webkit-background-clip: text;
                //-webkit-text-fill-color: transparent;
            }

            .item-right {
                width: 40%;
                margin-top: 8px;
                margin-left: 2%;

                img {
                    width: 16px;
                    height: 16px;
                }
            }

        }
    }


    #User .tablist {
        padding-top: 0.20rem;
        padding-bottom: 1.14rem;
    }

    #User .tablist .lists {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        /*justify-content: center;*/
        margin-bottom: 0.2rem;
        text-align: center;
        padding: 10px;
    }

    #User .tablist .lists .icon {
        width: 35px;
        top: 5px;
        position: relative;
        margin: 10px auto 20px;
        display: block;
    }

    #User .tablist .lists .item-d {
        width: 30%;
        margin: 10px 1%;
        height: 120px;
        background: #ffffff;
        font-size: 0.3rem;
        color: #222222;
        border-radius: 10px;
    }

    #User .tablist .lists .item-d .icongengduo1 {
        position: absolute;
        right: 0rem;
    }

    #User .tablist .lists .item-d .iconyuebao {
        padding-left: .3rem;
    }

    #User .tablist .lists .item-d .iconyuebao::before {
        content: '';
        position: absolute;
        top: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: .3rem;
        height: .32rem;
        //background: center url(xgjt_m/static/img/yeb1@3x.6d83a6b.png) no-repeat;
        background-size: contain;
        left: .23rem;
    }

    #User .tablist .lists .item-d .next {
        position: absolute;
        margin-right: 0;
        right: 0;
        color: #d3d3d3;
        top: 0.2rem;
        height: 0.54rem;
        display: inline-block;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    #User .tablist .lists .item-d .msg-num {
        position: absolute;
        right: 0.6rem;
        top: 0.33rem;
        width: 0.32rem;
        height: 0.32rem;
        line-height: 0.32rem;
        font-size: 0.2rem;
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        background-color: #f05b54;
        border-radius: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #fff;
    }

    #User .tablist .lists .grap {
        color: #b2b2b2 !important;
    }

    #User .tablist2 {
        padding-top: 0rem;
        background: url('@/assets/images/tab4/zoushi-bg.png');
        background-size: 100% 100%;
        min-height: 650px;
    }

    .service {
        position: relative;
    }

    .service span {
        position: absolute;
        right: 0.6rem;
        top: 0.33rem;
    }

    @import '@/assets/css/app.css';</style>
