<template>
  <div class="container">
    <div class="homecontainer">
      <div class="top">
        <div class="name">
          <div class="text">{{ username }}</div>
        </div>
        <div class="title">{{ $t('home.Pionexpro') }}</div>
        <div class="ling-box">
          <img class="ling" src="../../static/img/home/ling.svg" />
        </div>
      </div>

      <div class="k-wrapper">
        <div class="k-box">
          <div class="header-box">
            <div class="header">
              <div class="left-box">
                <p class="big-text txtgreen" v-if="selectlist[8] > 0">{{ selectlist[2] }}</p>
                <p class="big-text txtred" v-else>{{ selectlist[2] }}</p>
                <p v-if="selectlist[8] > 0" class="txtgreen percent-text">
                  {{ selectlist[8] }}%
                </p>
                <p v-else class="txtred percent-text">{{ selectlist[8] }}%</p>
              </div>
              <div class="right-box">
                <div class="right-item">
                  <div class="right-item-left">High</div>
                  <div class="right-item-right">{{ selectlist[11] }}</div>
                </div>
                <div class="right-item">
                  <div class="right-item-left">Low</div>
                  <div class="right-item-right">{{ selectlist[12] }}</div>
                </div>
                <div class="right-item">
                  <div class="right-item-left">24H</div>
                  <div class="right-item-right">{{ selectlist[13] }}</div>
                </div>
              </div>
            </div>
            <div class="times">
              <div
                  class="time-item"
                  :class="timeSelected === index? 'active': ''"
                  v-for="(item, index) in timelist"
                  @click="gettimelist(item.value, index)"
                  :key="index"
              >{{ item.name }}</div>
            </div>
          </div>

          <div class="chart_container">
            <div id="myChart" :style="{ width: '100%', height: '337px' }"></div>
            <div class="pane-legend">
              <div class="pane-legend-item">
                <div class="tag">O</div>
                <div class="value txtgreen" v-if="selectlist[8] > 0">{{ selectlist[2] }}</div>
                <div class="value txtred" v-else>{{ selectlist[2] }}</div>
              </div>
              <div class="pane-legend-item">
                <div class="tag">H</div>
                <div class="value txtgreen" v-if="selectlist[8] > 0">{{ selectlist[11] }}</div>
                <div class="value txtred" v-else>{{ selectlist[11] }}</div>
              </div>
              <div class="pane-legend-item">
                <div class="tag">L</div>
                <div class="value txtgreen" v-if="selectlist[8] > 0">{{ selectlist[12] }}</div>
                <div class="value txtred" v-else>{{ selectlist[12] }}</div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div class="vip-wrap">
        <div class="pancel">
          <div class="quota-wrap">
            <div class="item">
              <div class="title">Authorization Quota</div>
              <div class="value">{{ Number(balancelist.banlance).toFixed(2) }}</div>
            </div>
            <div class="item">
              <div class="title">Lock limit</div>
              <div class="value">{{ Number(balancelist.financial).toFixed(2) }}</div>
            </div>
            <div class="item">
              <div class="title">Available Quota</div>
              <div class="value">{{ Number(balancelist.spot).toFixed(2) }}</div>
            </div>
          </div>

          <div class="level-item-li" :style="{backgroundImage: `url(${levelImgs[zhiyamorenindex]})`}" @click="xuanzezhiya">
            <div class="text1">Weight range:{{ selectzhiyalist.integral }} - {{ selectzhiyalist.integral_max }}</div>
            <div class="text">Quota range {{ selectzhiyalist.stnum }} - {{ selectzhiyalist.maxstrnum }} USDT</div>
            <div class="text">Maximum profit {{ selectzhiyalist.rate_max }}%<span style="margin-left: 20px">Maximum loss {{ selectzhiyalist.loss }}%</span></div>
            <div class="text">expected rate of return:{{ selectzhiyalist.rate_min }}% - {{ selectzhiyalist.rate_max }}%</div>
          </div>
        </div>

        <div v-if="!istijiao" class="plane">
          <div >
            <div class="cell-li">
              <div class="title">Select trading area</div>
              <div class="value">
                <van-field
                    v-model="fieldValue"
                    readonly
                    placeholder="Selct coin"
                    @click="showPicker = true"
                    style="background: rgba(255, 255, 255, 0)"
                    input-align="right"
                />
              </div>
            </div>
            <div class="cell-li">
              <div class="title">leverage multiplier</div>
              <div class="value">
                <van-field
                    v-model="fieldValue_multiplier"
                    readonly
                    placeholder="Select multiplier"
                    @click="showPicker_multiplier = true"
                    style="background: rgba(255, 255, 255, 0)"
                    input-align="right"
                />
              </div>
            </div>
            <div class="cell-li">
              <div class="title">Planned amount</div>
              <div class="value">
                <van-field
                    @input="amountinput"
                    v-model="amount"
                    type="number"
                    placeholder="Please enter the planned amount"
                    style="background: rgba(255, 255, 255, 0)"
                    input-align="right"
                />
              </div>
            </div>
          </div>

          <div class="violet-btns">
            <div class="vip-btn" @click="submit">Determine the plan</div>
            <div class="vip-btn1" @click="gotoorders">Order Record</div>
          </div>
        </div>

        <div  v-else class="placeanorder_kuang_tijiao">
          <div class="textc">
            <img class="textcimg" src="../../static/new/tab1/Medicine.png" />
            <p class="fs18 fw500 txtkx6">You have successfully<br />Buy a plan!</p>
          </div>
          <div class="dingdaninfo">
            <p class="displayflex">
              <span class="fs12 fw400 txtkx1">Trading Area</span
              ><span class="fs12 fw400">{{ sub.coin }}/USDT</span>
            </p>
            <p class="displayflex">
              <span class="fs12 fw400 txtkx1">Leverage Multiplier</span
              ><span class="fs12 fw400">{{ sub.multiple }}X</span>
            </p>
            <p class="displayflex">
              <span class="fs12 fw400 txtkx1">Amount</span
              ><span class="fs12 fw400">{{ sub.price }}</span>
            </p>
          </div>
          <div class="buttom_info displayflex">
            <div @click="istijiao = false" class="fs14 fw600 jixu">Staking Again</div>
            <div @click="gotoorders" class="fs14 fw600 dingdanorder">View History</div>
          </div>
        </div>

        <div style="height: 40px"></div>
      </div>

  </div>
  <van-popup v-model:show="showPicker" round position="bottom">
    <van-picker
      :columns="coincolumns"
      @cancel="showPicker = false"
      @confirm="onConfirm"
      confirm-button-text="Confirm"
      cancel-button-text="Cancel"
    />
  </van-popup>
  <van-popup v-model:show="showPicker_multiplier" round position="bottom">
    <van-picker
      :columns="multipliercolumns"
      @cancel="showPicker_multiplier = false"
      @confirm="onConfirm_multiplier"
      confirm-button-text="Confirm"
      cancel-button-text="Cancel"
    />
  </van-popup>
  <van-popup
    v-model:show="xuanzezyshow"
    class="level-pop"
  >
    <div>
      <div style="display: flex; justify-content: right;">
        <img
            src="../../static/new/tab1/plan-close.svg"
            @click="xuanzezyshow = false"
            style="width: 20px"
        />
      </div>
      <div class="fs18" style="margin-bottom: 10px">
        <p class="margin-bottom-5">Select level plan</p>
        <p class="fs12">Your current weight score is:{{ jiesuojifen }}</p>
      </div>
    </div>

    <div style="overflow: auto;height: 455px">
      <template v-for="(item, index) in zhiyalist" :key="index">
		
		<div
			v-if="index==0"
		    @click="xuanzequedingzhiya(index)"
		    :style="{backgroundImage: `url(${levelImgs[index]})`}"
		    class="level-item-li"
		>
		  <div class="text1">Weight range:{{ item.integral }} - {{ item.integral_max }}</div>
		  <div class="text">Quota range {{ item.stnum }} - {{ item.maxstrnum }} USDT</div>
		  <div class="text2">Maximum profit {{ item.rate_max }}%<span style="margin-left: 20px">Maximum loss {{ item.loss }}%</span></div>
		  <div class="text">expected rate of return:{{ item.rate_min }}% - {{ item.rate_max }}%</div>
		</div>
		  
        <div
			v-else-if="Number(jiesuojifen) >= item.integral && Number(balancelist.banlance) >= item.stnum"
            @click="xuanzequedingzhiya(index)"
            :style="{backgroundImage: `url(${levelImgs[index]})`}"
            class="level-item-li"
        >
          <div class="text1">Weight range:{{ item.integral }} - {{ item.integral_max }}</div>
          <div class="text">Quota range {{ item.stnum }} - {{ item.maxstrnum }} USDT</div>
          <div class="text2">Maximum profit {{ item.rate_max }}%<span style="margin-left: 20px">Maximum loss {{ item.loss }}%</span></div>
          <div class="text">expected rate of return:{{ item.rate_min }}% - {{ item.rate_max }}%</div>
        </div>
		
		<div
			v-else
		    :style="{backgroundImage: `url(${levelImgs[index]})`}"
		    class="level-item-li"
		>
		  <div class="text1">Weight range:{{ item.integral }} - {{ item.integral_max }}</div>
		  <div class="text">Quota range {{ item.stnum }} - {{ item.maxstrnum }} USDT</div>
		  <div class="text2">Maximum profit {{ item.rate_max }}%<span style="margin-left: 20px">Maximum loss {{ item.loss }}%</span></div>
		  <div class="text">expected rate of return:{{ item.rate_min }}% - {{ item.rate_max }}%</div>
		  <div class="lock-wrapper">
		    <img src="../../static/img/home/icon-lock.svg" style="width: 38px;height: 38px">
		  </div>
		</div>
		
      </template>
    </div>
  </van-popup>
</template>

<script>
import {
  toRefs,
  reactive,
  onMounted,
  getCurrentInstance,
  nextTick,
  onBeforeUnmount,
} from "vue";
import { useRouter } from "vue-router";

import {
  getallcoinList,
  kxianbData,
  Financin,
  getmultiple,
  user_center,
  SubFinancin,
  getuserbalances,
} from "@/api/api";
import { showToast } from "vant";
import * as echarts from "echarts";

import leveimg1 from '../../static/new/tab1/1682694953_68570.png'
import leveimg2 from '../../static/new/tab1/1682694962_81867.png'
import leveimg3 from '../../static/new/tab1/1682694967_18052.png'
import leveimg4 from '../../static/new/tab1/1682694974_20183.png'
import leveimg5 from '../../static/new/tab1/1682694982_12147.png'
import leveimg6 from '../../static/new/tab1/1682694990_69947.png'

export default {
  setup() {
    let router = useRouter();

    const { proxy } = getCurrentInstance();
    // 数据
    const State = reactive({
      username: "",
      levelImgs:[
        leveimg1,
        leveimg2,
        leveimg3,
        leveimg4,
        leveimg5,
        leveimg6,
      ],
      // timelist: ["1m", "5m", "15m", "30m", "1h", "6h", "1d"], //默认是5m
      timeSelected: 1,//默认是5m
      timelist: [
          {name:'Time', value: ''},
          // {name:'1min', value: '1m'},//接口不支持
          {name:'5min', value: '5m'},
          {name:'30min', value: '30m'},
          {name:'1hour', value: '1h'},
          {name:'6hour', value: '6h'},//接口不支持
          {name:'1day', value: '1d'},
          // {name:'1week', value: '7d'}//接口不支持
      ],
      query: {
        id: 1, //交易区，默认传1
        type: 0, //0 货币
      },
      list: [],
      selectlist: [],

      echartsData: {
        time: [],
        value: [],
      },
      defaultdatanum: 0,
      uptime: null,

      xdsetp: 0,

      fieldValue: "",
      showPicker: false,
      coincolumns: [],

      fieldValue_multiplier: "",
      showPicker_multiplier: false,
      multipliercolumns: [],

      amount: "",

      kxcoinname: "ETH",
      kxtime: "5m",

      istijiao: false,

      xuanzezyshow: false,
      zhiyalist: [],

      zhiyamorenindex: 0,

      selectzhiyalist: [],

      maxprofit: 0,
      jisuoprofit: 0,

      sub: {
        id: "", //质押id
        price: "", //金额
        multiple: "", //选择的倍数
        coin: "", //选择的货币
      },

      balancelist: [],

      jiesuojifen: 0,

    });
    // 方法
    const Function = reactive({
      onload() {
        const usernames = sessionStorage.getItem("username");
        State.username = usernames.substr(-6);
		sessionStorage.setItem('iszidongbofang', "false")
        getuserbalances().then((res) => {
          //console.log(res.data)
          State.balancelist = res.data.url;
        });

        user_center().then((res) => {
          State.jisuoprofit = res.data.url.multiple;
          State.jiesuojifen = res.data.url.integral;
          //console.log(res.data)
		  getmultiple().then((res) => {
		    State.maxprofit = res.data.url;
		    //console.log(State.maxprofit)
		    for (let i = 1; i <= State.maxprofit; i++) {
				/*
				if(i > Number(State.jisuoprofit)){
					break
				}
				*/
		      const profitarray = { text: i, value: i };
		      State.multipliercolumns.push(profitarray);
		    }
		  });
        });



        Financin().then((res) => {
          State.zhiyalist = res.data.info;
          State.selectzhiyalist = State.zhiyalist[State.zhiyamorenindex];
          State.sub.id = State.selectzhiyalist.id;
        });

        getallcoinList(State.query).then((res) => {
          const { data } = res;
          State.list = data.url;
          State.list.forEach((item) => {
             const pushdata = { text: item[0], value: item[0] };
            //const pushdata = { text: item[0] };
            State.coincolumns.push(pushdata);
          });
          State.selectlist = State.list[1];
          State.sub.coin = State.selectlist[0];
          //console.log(State.list)
          //console.log(State.coincolumns)
          Function.getkxianbData();
        });
      },
      gotoDeposit() {
        router.push({
          path: "/deposit",
        });
      },
      gototixian() {
        router.push({
          path: "/withdraw",
        });
      },
      gotoorders() {
        router.push({
          path: "/orders",
        });
      },
      gotofaq() {
        router.push({
          path: "/faq",
        });
      },
      getLineData(index){
        return State.echartsData.data.map(item=>item[index])
      },
      xuanzequedingzhiya(index) {
        State.zhiyamorenindex = index;
        State.selectzhiyalist = State.zhiyalist[State.zhiyamorenindex];
        State.sub.id = State.selectzhiyalist.id;
        State.xuanzezyshow = false;
      },
      xuanzezhiya() {
        State.xuanzezyshow = true;
      },
      onConfirm({ selectedOptions }) {
        //console.log(selectedOptions)
        State.showPicker = false;
        State.fieldValue = selectedOptions[0].text;
        //State.registerdata.qz = selectedOptions[0].value

        State.xdsetp = 1;

        State.kxcoinname = selectedOptions[0].value;
        State.list.forEach((item) => {
          if (item[0] == State.kxcoinname) {
            State.selectlist = item;
            State.sub.coin = State.selectlist[0];
          }
        });

        Function.getkxianbData();
      },
      onConfirm_multiplier({ selectedOptions }) {
        //console.log(selectedOptions)
        State.showPicker_multiplier = false;
        if (Number(selectedOptions[0].value) > Number(State.jisuoprofit)) {
          showToast("This leverage ratio has not been unlocked yet");
          return false;
        }
        State.fieldValue_multiplier = selectedOptions[0].text;
        State.sub.multiple = selectedOptions[0].value;

        State.xdsetp = 2;
      },
      amountinput(e) {
        if (State.amount) {
          State.xdsetp = 3;
        } else {
          State.xdsetp = 2;
        }
      },
      jump() {
        router.push({
          path: "/Notifications",
        });
      },
      submit() {
        State.sub.price = State.amount;
        //console.log('点击了提交',State.sub)
        if (!State.sub.price) {
          showToast("Please enter amount");
          return false;
        }
        if (!State.sub.multiple) {
          showToast("Please select leverage multiplier");
          return false;
        }
        SubFinancin(State.sub).then((res) => {
          //console.log(res.data)
          if (res.data.status) {
            State.istijiao = true;
            showToast(res.data.info);
          } else {
            showToast(res.data.info);
          }
        });
      },
      gettimelist(timestring, index) {
        //console.log(timestring)
        State.timeSelected = index
        State.kxtime = timestring;
        Function.getkxianbData();
      },
      // K线数据
      getkxianbData() {
        let data = {
          name: State.kxcoinname,
          time: State.kxtime,
        };
        kxianbData(data)
          .then((res) => {
            //console.log(res)
            //console.log('数据显示')

            var time = [];
            var value = [];
            var volume = [];
            var data = [];
            if (res.data.info.length > 0) {
              res.data.info.forEach((item) => {
                time.push(item.time);
                value.push(item.value);
                volume.push(parseInt(item.volume));
                data.push(item.data.split(","));
              });
            }
            State.defaultdatanum = data.length - 1;
            State.echartsData = {
              time,
              value,
              volume,
              data,
            };
            console.log(JSON.stringify(State.echartsData))
            //console.log(State.echartsData)
            nextTick(() => {
              Function.setEcharts(State.timeSelected===0);
            });
          })
          .catch((err) => {
            console.log(err);
            nextTick(() => {
              Function.setEcharts(State.timeSelected===0);
            });
          });
      },
      setEcharts(isBar) {
        let dsiab_com = document.getElementById("myChart");
        // dsiab_com.removeAttribute("_echarts_instance_");
        //const myChart = echarts.init(dsiab_com);

        //let myChart = echarts.init(document.getElementById("myChart"));
        let myChart = echarts.init(dsiab_com);
        let option
        if (isBar){
          option = {
            title: {
              show: false,
              text: "",
              left: 0,
            },
            axisPointer: {
              label: {
                backgroundColor: "#000",
                textStyle: {
                  color: "#ffffff",
                  fontSize: 12,
                },
              },
              link: {
                xAxisIndex: "all",
              },
            },
            grid: [
              {
                left: "0",
                right: "15%",
                bottom: "0%",
                top: "5%",
                height: "75%",
              },
              {
                left: "0",
                right: "15%",
                top: "80%",
                height: "13%",
              },
            ],
            xAxis: [
              {
                type: "category",
                gridIndex: 0,
                data: State.echartsData.time,
                scale: true,
                boundaryGap: true,
                axisLine: {
                  show: false,
                  lineStyle: {
                    color: "#787878",
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: false,
                },
                axisLabel: {
                  show: false,
                  textStyle: {
                    color: "#61688A",
                    fontSize: 12,
                  },
                },
                min: "dataMin",
                max: "dataMax",
                axisPointer: {
                  z: 100,
                },
              },
              {
                type: "category",
                gridIndex: 1,
                data: State.echartsData.time,
                scale: true,
                boundaryGap: true,
                axisLine: {
                  onZero: true,
                  lineStyle: {
                    color: "#787878",
                  },
                },
                axisTick: {
                  show: true,
                  interval: 1,
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    color: ["#787878"],
                  },
                },
                splitNumber: 20,
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "#61688A",
                    fontSize: 12,
                  },
                },
                min: "dataMin",
                max: "dataMax",
                axisPointer: {
                  label: {},
                },
              },
            ],
            yAxis: [
              {
                scale: true,
                gridIndex: 0,
                splitArea: {
                  show: false,
                },
                axisTick: {
                  show: true,
                },
                splitLine: {
                  show: false,
                },
                axisLabel: {
                  textStyle: {
                    color: "#61688A",
                    fontSize: 12,
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#787878",
                  },
                },
                position: "right",
                min: "dataMin",
                max: "dataMax",
              },
              {
                scale: true,
                gridIndex: 1,
                axisLabel: {
                  show: false,
                  textStyle: {
                    color: "61688A",
                    fontSize: 12,
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#787878",
                  },
                },
                axisTick: {
                  show: true,
                  lineStyle: {},
                },
                splitLine: {
                  show: false,
                },
                axisPointer: {
                  label: {},
                },
                min: "0",
                max: "dataMax",
                position: "right",
                interval: 271857,
              },
            ],
            dataZoom: [
              {
                type: "inside",
                xAxisIndex: [0],
                startValue: 370,
                endValue: 399,
                zoomLock: false,
              },
            ],
            series: [
              {
                name: "Volumn",
                type: "line",
                areaStyle: {
                  color: 'rgba(15,128,140, .6)'
                },
                xAxisIndex: 0,
                yAxisIndex: 0,
                data: State.echartsData.volume,
                // data: State.echartsData.value,
                itemStyle:{ opacity: 0 },
                lineStyle: {
                  opacity: 0.8,
                  width: 1,
                  type: "solid",
                  color: "#076CB4"
                },
              },
              {
                type: 'line',
                data: State.echartsData.value,
                smooth: true,
                lineStyle:{
                  opacity: 0.8,
                  width: 1,
                  type: "solid",
                  color: "#F07878"
                },
                itemStyle:{ opacity: 0 }
              },
              {
                name: "Volumn",
                type: "bar",
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: State.echartsData.volume,
                barCategoryGap: '0%',
                itemStyle: {
                  normal: {
                    borderWidth: 0.5,
                    borderColor: "#787878",
                    // borderColor: "#2ABD87",
                    // borderColor0: "#E86D43",
                    color: (params) => {
                      console.log('params:'+JSON.stringify(params))
                      var color1 = 'rgba(42,189,135,.5)';
                      var color2 = 'rgba(232,109,67, .5)';
                      var colorList;
                      if (
                          State.echartsData.data[params.dataIndex][1] >
                          State.echartsData.data[params.dataIndex][0]
                      ) {
                        colorList = color1;
                      } else {
                        colorList = color2;
                      }
                      return colorList;
                    },
                  },
                },
              },
              // {
              //   type: 'line',
              //   data: Function.getLineData(1),
              //   smooth: true,
              //   lineStyle:{
              //     opacity: 0.8,
              //     width: 1,
              //     type: "solid",
              //     color: "#91CC75"
              //   },
              //   itemStyle:{ opacity: 0 }
              // },
              // {
              //   type: 'line',
              //   data: Function.getLineData(2),
              //   smooth: true,
              //   lineStyle:{
              //     opacity: 0.8,
              //     width: 1,
              //     type: "solid",
              //     color: "#FAC858"
              //   },
              //   itemStyle:{ opacity: 0 }
              // },
              // {
              //   type: 'line',
              //   data: Function.getLineData(3),
              //   smooth: true,
              //   lineStyle:{
              //     opacity: 0.8,
              //     width: 1,
              //     type: "solid",
              //     color: "#5470C6"
              //   },
              //   itemStyle:{ opacity: 0 }
              // }
            ],
            backgroundColor: "#2f3241",
          };
        } else {
          // 绘制图表
          option = {
            title: {
              show: false,
              text: "",
              left: 0,
            },
            axisPointer: {
              label: {
                backgroundColor: "#000",
                textStyle: {
                  color: "#ffffff",
                  fontSize: 12,
                },
              },
              link: {
                xAxisIndex: "all",
              },
            },
            grid: [
              {
                left: "0",
                right: "15%",
                bottom: "0%",
                top: "5%",
                height: "75%",
              },
              {
                left: "0",
                right: "15%",
                top: "80%",
                height: "13%",
              },
            ],
            xAxis: [
              {
                type: "category",
                gridIndex: 0,
                data: State.echartsData.time,
                scale: true,
                boundaryGap: true,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#787878",
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: false,
                },
                axisLabel: {
                  show: false,
                  textStyle: {
                    color: "#61688A",
                    fontSize: 12,
                  },
                },
                min: "dataMin",
                max: "dataMax",
                axisPointer: {
                  z: 100,
                },
              },
              {
                type: "category",
                gridIndex: 1,
                data: State.echartsData.time,
                scale: true,
                boundaryGap: true,
                axisLine: {
                  onZero: true,
                  lineStyle: {
                    color: "#787878",
                  },
                },
                axisTick: {
                  show: true,
                  interval: 1,
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    color: ["#787878"],
                  },
                },
                splitNumber: 20,
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "#61688A",
                    fontSize: 12,
                  },
                },
                min: "dataMin",
                max: "dataMax",
                axisPointer: {
                  label: {},
                },
              },
            ],
            yAxis: [
              {
                scale: true,
                gridIndex: 0,
                splitArea: {
                  show: false,
                },
                axisTick: {
                  show: true,
                },
                splitLine: {
                  show: false,
                },
                axisLabel: {
                  textStyle: {
                    color: "#61688A",
                    fontSize: 12,
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#787878",
                  },
                },
                position: "right",
                min: "dataMin",
                max: "dataMax",
              },
              {
                scale: true,
                gridIndex: 1,
                axisLabel: {
                  show: false,
                  textStyle: {
                    color: "61688A",
                    fontSize: 12,
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#787878",
                  },
                },
                axisTick: {
                  show: true,
                  lineStyle: {},
                },
                splitLine: {
                  show: false,
                },
                axisPointer: {
                  label: {},
                },
                min: "0",
                max: "dataMax",
                position: "right",
                interval: 271857,
              },
            ],
            dataZoom: [
              {
                type: "inside",
                xAxisIndex: [0,1],
                startValue: 370,
                endValue: 399,
                zoomLock: false,
              },
            ],
            series: [
              {
                name: "日K",
                type: "candlestick",
                data: State.echartsData.data,
                itemStyle: {
                  normal: {
                    color: "#2ABD87",
                    color0: "#E86D43",
                    borderColor: "#2ABD87",
                    borderColor0: "#E86D43",
                  },
                },
                markArea: {
                  silent: true,
                  itemStyle: {
                    normal: {
                      color: "#f2dbdb",
                      borderWidth: 1,
                      borderType: "dashed",
                      borderColor: "#f2dbdb",
                    },
                  },
                  data: [],
                },
                markLine: {
                  symbol: ["none", "none"],
                  lineStyle: {
                    normal: {
                      color: "#fff",
                      width: 2,
                    },
                    emphasis: {
                      color: "#da9be8",
                      width: 2,
                    },
                  },
                },
              },
              {
                type: 'line',
                data: State.echartsData.value,
                smooth: true,
                lineStyle:{
                  opacity: 0.5,
                  width: 2,
                  type: "solid",
                  color: "#F07878"
                },
                itemStyle:{ opacity: 0 }
              },
              {
                name: "Volumn",
                type: "bar",
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: State.echartsData.volume,
                barCategoryGap: '0%',
                itemStyle: {
                  normal: {
                    borderWidth: 0.5,
                    borderColor: "#787878",
                    // borderColor: "#2ABD87",
                    // borderColor0: "#E86D43",
                    color: (params) => {
                      console.log('params:'+JSON.stringify(params))
                      var color1 = 'rgba(42,189,135,.5)';
                      var color2 = 'rgba(232,109,67, .5)';
                      var colorList;
                      if (
                          State.echartsData.data[params.dataIndex][1] >
                          State.echartsData.data[params.dataIndex][0]
                      ) {
                        colorList = color1;
                      } else {
                        colorList = color2;
                      }
                      return colorList;
                    },
                  },
                },
              },
              // {
              //   type: 'line',
              //   data: Function.getLineData(1),
              //   smooth: true,
              //   lineStyle:{
              //     opacity: 0.5,
              //     width: 2,
              //     type: "solid",
              //     color: "#91CC75"
              //   },
              //   itemStyle:{ opacity: 0 }
              // },
              // {
              //   type: 'line',
              //   data: Function.getLineData(2),
              //   smooth: true,
              //   lineStyle:{
              //     opacity: 0.5,
              //     width: 2,
              //     type: "solid",
              //     color: "#FAC858"
              //   },
              //   itemStyle:{ opacity: 0 }
              // },
              // {
              //   type: 'line',
              //   data: Function.getLineData(3),
              //   smooth: true,
              //   lineStyle:{
              //     opacity: 0.5,
              //     width: 2,
              //     type: "solid",
              //     color: "#5470C6"
              //   },
              //   itemStyle:{ opacity: 0 }
              // }
            ],
            backgroundColor: "#2f3241",
          };
        }
        myChart.setOption(option, true);
        /*
						if(typeof myChart.isDisposed()=='undefined'){
							State.uptime = setTimeout(() => {


								let valarray = State.echartsData.value
								valarray.sort(function(a, b) {
									return a - b;
								})
								let valcount = State.echartsData.value.length - 1;
								let valmin = valarray[0]
								let valmax = valarray[valcount]
								let randomval = Math.floor(Math.random() * (valmax - valmin) + valmin);//value

								const date = new Date();
								const year = date.getFullYear()
								const month = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
								const day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
								const h = date.getHours()
								const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
								const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())

								let timeval =  `${year}-${month}-${day} ${h}:${m}:${s}`;//time


								let ovalarray = State.echartsData.volume
								ovalarray.sort(function(a, b) {
									return a - b;
								})
								let ovalcount = State.echartsData.volume.length - 1;
								let ovalmin = ovalarray[0]
								let ovalmax = ovalarray[ovalcount]
								let randomoval = Math.floor(Math.random() * (ovalmax - ovalmin) + ovalmin);//volume


								let datacount = State.defaultdatanum;
								State.echartsData.data[datacount].sort(function(a, b) {
									return a - b;
								})
								let dataval = [];//data
								for(let i=0;i<4;i++){
									let datamin = State.echartsData.data[datacount][0]
									let datamax = State.echartsData.data[datacount][3]
									let randodata = String(Math.floor(Math.random() * (Number(datamax) - Number(datamin)) + Number(datamin)))
									dataval.push(randodata)
								}

								State.echartsData.time.push(timeval)
								State.echartsData.value.push(randomval)
								State.echartsData.data.push(dataval)
								State.echartsData.volume.push(randomoval)

								Function.setEcharts()
							}, 60000)
						}
						*/
      },
    });

    onBeforeUnmount(() => {
      clearInterval(State.uptime);
      State.uptime = null;
    });
    // 接口
    const Interface = reactive({});

    onMounted(() => {
      Function.onload();
    });
    return {
      ...toRefs(State),
      ...toRefs(Function),
      ...toRefs(Interface),
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  height: auto;
}
.homecontainer {
  width: 100% !important;
  margin: 0 auto;
  background: #2f3241 !important;
  height: auto;
  color: #ffffff;
  font-family: Sora;
}
.top {
  height: 44px;
  background-image: url('../../static/img/home/home-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .name {
    position: absolute;
    left: 0;
    padding: 0 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .text{
      font-weight: 400;
      color: rgb(0, 0, 0);
      padding: 3px 7px;
      border-radius: 5px;
      font-size: 16px;
      border: 1px solid rgb(0, 0, 0);
    }
  }
  .title{
    font-weight: 600;
    width: 200px;
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 16px;
  }
  .ling-box{
    position: absolute;
    right: 0;
    padding: 0 13px;
    .ling {
      fill: rgb(2, 2, 2);
      width: 25px;
      height: 25px;
      margin: 0 10px;
      font-size: 13px;
      border-radius: 10px;
    }
  }
}

.k-wrapper{
  background-color: #2f3241;
  .k-box{
    margin-top: 10px;
    .header-box{
      .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        .left-box{
          display: flex;
          flex-direction: column;
          align-items: center;

          .big-text{
            font-size: 28px;
            font-weight: bold;
          }
          .percent-text{
            font-size: 20px;
            font-weight: bold;
          }
        }
        .right-box{
          display: flex;
          flex-direction: column;
          align-items: center;
          .right-item{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            min-width: 100px;
            align-items: center;
            margin: 5px;
            font-size: 12px;
            .right-item-left {
              color: #9FBEDC;
            }
            .right-item-right {
              color: #FFFFFF;
            }
          }
        }
      }
      .times{
        display: flex;
        .time-item{
          flex: 1;
          height: 22px;
          margin: 0 5px;
          line-height: 25px;
          color: #7c8bac;
          font-size: 12px;
          text-align: center;
        }
        .active{
          position: relative;
          color: #157fd5;
          font-weight: 500;
          border-bottom: 1px solid #157fd5;
        }
      }
    }

    .chart_container{
      width: 100%;
      position: relative;
      .pane-legend{
        position: absolute;
        left: 0;
        top: 10px;
        display: flex;
        .pane-legend-item{
          display: flex;
          margin-left: 10px;
          .tag{
            color: #616889;
            font-size: 12px;
          }
          .value{
            margin-left: 5px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
/******************************************************************************************************/
.vip-wrap{
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 9px 16px;
  background-image: url(/static/img/home/home-bg.png);
  background-color: rgb(255, 255, 255);
  background-attachment: fixed;
  background-size: auto;
  font-weight: 600;
  .pancel{
    padding-bottom: 5px;
    border-radius: 7px;
    background-color: rgba(0, 117, 253, 0.2);
    display: flex;
    flex-direction: column;
    width: 100%;
    .quota-wrap{
      margin: 10px 7px;
      border-radius: 6px;
      position: relative;
      display: flex;
      .item{
        flex: 1;
        text-align: center;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
          color: rgb(153, 153, 153);
          font-size: 12px;
        }
        .value{
          margin-top: 9px;
          color: black;
          font-size: 14px;
        }
      }
    }

    .level-item-li{
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: 100% 100%;
      // background-image: url('../../static/new/tab1/1682694953_68570.png');
      width: auto;
      margin: 3px 7px 0;
      padding: 12px 12px;
      height: 112px;
      position: relative;
      border-radius: 5px;
      .text1{
        margin-top: 5px;
        font-size: 14px;
        color: white;
      }
      .text{
        margin-top: 10px;
        font-size: 12px;
        color: white;
      }
    }
  }

  .plane{
    width: 100%;
    margin-top: 5px;
    .cell-li{
      margin-bottom: 5px;
      background-color: rgba(0, 117, 253, 0.2);
      border-radius: 7px;
      padding: 12px 12px;
      border-bottom: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: black;
      font-size: 13px;

      .van-cell{
        padding: 0;
      }
      :deep(.van-field__control) {
        color: black;
        font-size: 13px;
      }
      :deep(.van-field__control::-webkit-input-placeholder) {
        color: #808080 !important;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }

  .violet-btns{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 16px;
    font-size: 14px;
    color: white;
    .vip-btn{
      padding: 10px;
      background-image: linear-gradient(to right, rgb(0, 117, 253), rgb(73, 151, 253));
      width: 100%;
      height: 30px;
      border-radius: 7px;
      margin-top: 0;
      margin-bottom: 0;
      background-color: initial;
      text-align: center;
    }
    .vip-btn1{
      padding: 10px;
      margin-top: 15px;
      background-image: linear-gradient(to right, rgb(51, 206, 253), rgb(73, 151, 253));
      width: 100%;
      height: 30px;
      border-radius: 7px;
      margin-bottom: 0;
      background-color: initial;
      text-align: center;
    }
  }
}
:deep(.van-picker__cancel){
  font-weight: bold;
  font-size: 15px;
}
:deep(.van-picker__confirm){
  font-weight: bold;
  font-size: 15px;
}
:deep(.van-ellipsis){
  font-weight: bold;
  font-size: 15px;
}

.level-pop{
  width: 315px;
  height: 533px;
  padding: 15px;
  color: #ffffff;
  background: url('/static/img/home/change-lelvel-bg.png');
  background-size: 100% 100%;
  overflow: hidden;

  .level-item-li{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    //background-image: url('../../static/new/tab1/1682694953_68570.png');
    width: auto;
    padding: 12px 12px;
    margin-bottom: 2px;
    height: 87px;
    position: relative;
    border-radius: 5px;
    position: relative;
    .text1{
      margin-top: 3px;
      font-size: 12px;
      color: white;
      font-weight: bold;
    }
    .text2{
      margin-top: 12px;
      font-size: 10px;
      color: white;
      font-weight: bold;
    }
    .text{
      margin-top: 3px;
      font-size: 10px;
      color: white;
      font-weight: bold;
    }
    .lock-wrapper{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 5px;
    }
  }
}

.usertext {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}
.heng {
  height: 1px;
  background: #ffffff33;
}
.shuheng {
  width: 1px;
  height: 32px;
  background: #252b42;
}

.kxian {
  width: 100%;
  height: 296px;
  background: #29346d33;
  margin-top: 50px;
}
.coinprick {
  width: 90%;
  margin: 0 auto;
  padding: 8.88px 0px 8.88px 0px;
  height: 68px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.coinxq {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.coinxqinfo {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.kxtime {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.kxcontent {
  width: 90%;
  height: 212px;
  margin: 0 auto;
}

.tureelist {
  width: 90%;
  height: 99px;
  margin: 0 auto;
  margin-top: 50px;
  background: linear-gradient(0deg, rgba(41, 52, 109, 0.2), rgba(41, 52, 109, 0.2)),
    linear-gradient(0deg, #16182b, #16182b);
  border-radius: 6px;
  padding: 24px 16px 24px 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.quick {
  width: 90%;
  height: 101px;
  margin: 0 auto;
  margin-top: 50px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.quicklist {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list_content {
  height: 71px;
  border-radius: 7.88px;
  padding: 11.83px 9.85px 11.83px 9.85px;
  background: #16182b;
}
.quickimg {
  width: 23px;
  margin-bottom: 7px;
}

.orderinfo {
  width: 90%;
  height: 190px;
  margin: 0 auto;
  margin-top: 50px;
  display: grid;
  grid-template-rows: auto auto auto auto;
  background: #16182b;
  border-radius: 6px;
  padding: 24px 16px 24px 16px;
}
.orderinfo_kuang {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.placeanorder {
  width: 90%;
  height: 518px;
  margin: 0 auto;
  margin-top: 50px;
  border-radius: 12px;
  border: 1.49px solid #252b42;
}
.placeanorder_title {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #16182b;
  padding: 0 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.placeanorder_kuang {
  width: 100%;
  height: 462px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 20px;
}
.placeanorder_kuang_tijiao {
  width: 100%;
  margin-top: 5px;
  padding: 20px;
  background-color: rgba(0, 117, 253, 0.2);
  border-radius: 7px;
}
.kuangimg {
  width: 24.16px;
  height: 247.2px;
}
.kuang_info {
  width: 267.64px;
  height: 462px;
  display: grid;
  grid-template-rows: auto auto auto auto;
}
.kuang_info_1 {
  width: 100%;
  height: 80.66px;
  display: grid;
  grid-template-rows: auto auto;
}
.kuang_info_select {
  width: 100%;
  height: 48px;
  border: 1px solid #2d2d5b;
  background: #060a19;
  border-radius: 8.92px;
}
.xdamount {
  background: rgba(255, 255, 255, 0);
  width: 100%;
  height: 100%;
  padding-left: 15px;
}
.xdbuttom {
  width: 100%;
  height: 39px;
  line-height: 39px;
  background: #526efe;
  color: #ffffff;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.dingdaninfo {
  width: 100%;
  height: 78px;
  display: grid;
  grid-template-rows: auto auto auto;
  margin-top: 50px;
}
.displayflex {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.buttom_info {
  width: 100%;
  height: 39px;
  margin-top: 50px;
}
.jixu {
  width: 48%;
  height: 39px;
  line-height: 39px;
  text-align: center;
  border-radius: 12px;
  background-image: linear-gradient(to right, rgb(0, 117, 253), rgb(73, 151, 253));
  color: white;
}
.dingdanorder {
  width: 48%;
  height: 39px;
  line-height: 39px;
  text-align: center;
  border-radius: 12px;
  background-image: linear-gradient(to right, rgb(51, 206, 253), rgb(73, 151, 253));
  color: white;
}

::placeholder {
  font-size: 10.41px;
  font-weight: 300;
  color: #a3aed0;
}

/deep/ ::placeholder {
  font-size: 10.41px;
  font-weight: 300;
  color: #a3aed0 !important;
}
/deep/ .van-field__control {
  color: #ffffff;
}

.txtbaise {
  color: #ffffff !important;
}

.txtkx1 {
  color: black;
}
.txtkx2 {
  color: #7df797;
}
.txtkx3 {
  color: #fff0d2;
}
.txtkx4 {
  color: #9dfff5;
}
.txtkx5 {
  color: #d7cbff;
}
.txtkx6 {
  color: #157fd5;
  font-size: 13px;
}
.txtred {
  color: rgb(193, 90, 88);
}
.txtgreen {
  color: #0dcb81;
}
.fs9_9 {
  font-size: 9.9px;
}
.fs9_36 {
  font-size: 9.36px;
}
.fs7_49 {
  font-size: 7.49px;
}
.fs12 {
  font-size: 12px;
}
.fs13_8 {
  font-size: 13.8px;
}
.fs13_5 {
  font-size: 13.5px;
}
.fs14 {
  font-size: 14px;
}
.fs15 {
  font-size: 15px;
}
.fs16 {
  font-size: 16px;
}
.fs18 {
  font-size: 18px;
}
.fs24 {
  font-size: 24px;
}
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.sjdbaise {
  color: #9b9ea4;
}
.textcolor1 {
  color: #a1abcf;
}
.w33 {
  width: 33.33%;
}
.w22 {
  width: 22%;
}
.textl {
  text-align: left;
}
.textc {
  text-align: center;
}
.textr {
  text-align: right;
}
.margin-bottom-5 {
  margin-top: -18px;
  margin-bottom: 5px;
  font-weight: bold;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.zdhh {
  white-space: normal;
  word-break: break-all;
}
.textcimg{
	width: 84px;
    height: 83.25px;
}
</style>
